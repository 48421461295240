import React, { useState } from "react";
import { FaStar } from "react-icons/fa";
import loginStyle from "./style.module.css";
import { Link, useNavigate } from "react-router-dom";
import { addUserOtpSignUp, userVerifyOtp, } from "../../Services/AllApi";
import toast from "react-hot-toast";


export default function UserLogin() {
  const [loading, setLoading] = useState(false);
  const [formUserData, setFormUserData] = useState({
    mobile: "",
    countryCode: "+91",
  });
  const navigate = useNavigate();

  const handleChangeUser = (e) => {
    const { name, value } = e.target;
    if (value === " ") {
      e.target.value = "";
    } else {
      setFormUserData((pre) => {
        return {
          ...pre,
          [name]: value,
        };
      });
    }
  };

  const handleUserSignIn = async (e) => {
    e.preventDefault();
    const fullMobileNumberUser = `${formUserData.countryCode}${formUserData.mobile}`;

    const payloadUser = { mobile: fullMobileNumberUser };
    const payloadUserVerify = {
      mobile: fullMobileNumberUser,
      otp: "0000",
    };
    setLoading(true);

    try {
      const response = await addUserOtpSignUp(payloadUser);
      if (payloadUser.mobile.includes("+971")) {
        const resverify = await userVerifyOtp(payloadUserVerify);

        const userToken = {
          token: resverify?.data?.token,
        };
        localStorage.setItem("UserDetail", JSON.stringify(userToken));
        if (resverify?.data?.userData?.firstname) {
          toast.success("login successfully");
          window.location.href = "https://eyemastr.com/";
        } else {
          navigate("/user-profile");
        }
      } else {
        toast.success("OTP has been sent on your Mobile Number");
        setFormUserData("");
        navigate("/verifyOTP", { state: { payloadUser } });
      }
      console.log(response);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  return (
    <div className={`registrationBanner p-md-5 p-3  `}>
      <div className={`container ${loginStyle.login_container}`}>
        <div className="mt-5">
          <div className={`row  justify-content-center`}>
            <div className="col-lg-6 col-md-10 col-12 px-md-3 px-2 py-4 rounded bg-white ">
              <div className="heading me-3">
                Log in to access your Eyemast
                <FaStar color="#007bff" />r account
              </div>
              <div className="text-gray me-3 mt-3">
                Don't have any account?
                <Link to="/signup">Create New</Link>
              </div>
              <form onSubmit={handleUserSignIn}>
                <div className="col-12 form-group mt-3">
                  <label className="labelHeading">
                    Mobile Number<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <select
                      name="countryCode"
                      style={{ maxWidth: "150px" }}
                        value={formUserData.countryCode}
                      className="form-select pointer"
                        onChange={handleChangeUser}
                    >
                      <option value="+91" className="pointer">
                        IN(+91)
                      </option>
                      <option value="+971" className="pointer">
                        UAE(+971)
                      </option>
                    </select>
                    <input
                      type="number"
                      placeholder="Enter your Mobile Number"
                      required
                      name="mobile"
                        value={formUserData.mobile}
                      className="form-control"
                        onChange={handleChangeUser}
                      
                    />
                  </div>
                </div>
                <div className="col-12 mt-5">
                  <button
                    className="btn btn-primary w-100"
                    type="submit"
                    disabled={loading}
                  >
                    {loading ? (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : (
                      "Login"
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
