import React, { useState, useEffect } from "react";
import { City, Country, State } from "country-state-city";
import { completeProfileOfUser, uploadImg } from "../../Services/AllApi";
import toast from 'react-hot-toast';
import { useNavigate } from "react-router-dom";


export default function UserProfileView() {
  const [profileImage, setProfileImage] = useState(null);
  const [img, setImg] = useState("");
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();


  const [formData, setFormData] = useState({
    date_of_birth: "",
    country: "",
    state: "",
    city: "",
    email:"",
    firstname: "",
    profile: {
      fileUrl: "",
      fileName: "",
    },
  });


  // Filter countries to show only India and UAE
  const countryOptions = Country.getAllCountries().filter(
    (e) => e.name === "India" || e.name === "United Arab Emirates"
  );

  // Load states when the country changes
  useEffect(() => {
    if (formData.country) {
      const states = State.getStatesOfCountry(formData.country);
      setStateList(states);
      setCityList([]); // Clear city list when country changes
    }
  }, [formData.country]);

  // Load cities when the state changes
  useEffect(() => {
    if (formData.state) {
      const cities = City.getCitiesOfState(formData.country, formData.state);
      setCityList(cities);
    }
  }, [formData.state]);

  const handleChangeUser = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleCountryChange = (e) => {
    const countryIsoCode = e.target.value;
    setFormData((prev) => ({ ...prev, country: countryIsoCode, state: "", city: "" }));
  };

  const handleStateChange = (e) => {
    const stateIsoCode = e.target.value;
    setFormData((prev) => ({ ...prev, state: stateIsoCode, city: "" }));
  };

  const handleFileInput = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImg(file);
      const imageUrl = URL.createObjectURL(file);
      setProfileImage(imageUrl);
    }
  };

  const handleImage = async () => {
    const formData = new FormData();
    formData.append('upload', img);
    try {
        const response = await uploadImg(formData);
        return response?.data?.data[0] || []
    } catch (error) {
        console.log(error);
    }
}

  const submitCompleteProfile = async(e)=>{
    if (!img) {
      toast.error("Select profile picture")
      return;
  }
    e.preventDefault();
    setLoading(true)

    const imageURl = await handleImage();
    let payload ={
      date_of_birth: formData.date_of_birth,
      country: formData.country,
      state: formData.state,
      city: formData.city,
      firstname: formData.firstname,
      email:formData.email,
      profile: imageURl,
    }

    try{
      const response = await completeProfileOfUser(payload)
      setLoading(false)
      toast.success("Your Profile has been Completed!")
      window.location.href="https://eyemastr.com/"

    }catch(err){
      console.log(err)
      setLoading(false)
    }
  }

  return (
    <div className="container">
      <div className="mt-5 heading">User Personal Information</div>
      <form onSubmit={submitCompleteProfile}>
      <div className="row mt-4">
        <div className="col-12 ">
          <div className="d-flex align-items-center flex-md-row flex-sm-row flex-column">
            <div className="profilePreview">
              {profileImage ? (
                <img src={profileImage} alt="Profile Preview" style={{ width: "auto", height: "100%" }} />
              ) : (
                <img src="/images/profile.png" alt="Profile Preview" style={{ width: "auto", height: "100%" }} />
              )}
            </div>
            <div className="ms-4 d-flex flex-column">
              <label className="labelHeading mb-1">
                Profile Picture<span className="text-danger">*</span>
              </label>
              <input
                type="file"
                accept="image/*"
                required
                className="form-control-file pointer"
                name="profile"
                onChange={handleFileInput}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row mb-5">
        <div className="col-md-4 col-12 mt-3">
          <label className="labelHeading mb-1">
            Name<span className="text-danger">*</span>
          </label>
          <input
            className="form-control"
            placeholder="Enter Name"
            name="firstname"
            value={formData.firstname}
            onChange={handleChangeUser}
            required
          />
        </div>

        <div className="col-md-4 col-12 mt-3">
          <label className="labelHeading mb-1">
            Date Of Birth<span className="text-danger">*</span>
          </label>
          <input
            className="form-control"
            type="date"
            placeholder="Enter Date Of Birth"
            name="date_of_birth"
            value={formData.date_of_birth}
            onChange={handleChangeUser}
            required
          />
        </div>

        <div className="col-md-4 col-12 mt-3">
          <label className="labelHeading mb-1">
            Email<span className="text-danger">*</span>
          </label>
          <input
            className="form-control"
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChangeUser}
            required
          />
        </div>

        <div className="col-md-4 col-12 mt-3">
          <label className="labelHeading mb-1">
            Country<span className="text-danger">*</span>
          </label>
          <select
            className="form-control"
            name="country"
            value={formData.country}
            onChange={handleCountryChange}
            required
          >
            <option value="">Select Country</option>
            {countryOptions.map((country) => (
              <option key={country.isoCode} value={country.isoCode}>
                {country.name}
              </option>
            ))}
          </select>
        </div>

        <div className="col-md-4 col-12 mt-3">
          <label className="labelHeading mb-1">
            State<span className="text-danger">*</span>
          </label>
          <select
            className="form-control"
            name="state"
            value={formData.state}
            onChange={handleStateChange}
            disabled={!formData.country}
            required
          >
            <option value="">Select State</option>
            {stateList.map((state) => (
              <option key={state.isoCode} value={state.isoCode}>
                {state.name}
              </option>
            ))}
          </select>
        </div>

        <div className="col-md-4 col-12 mt-3">
          <label className="labelHeading mb-1">
            City<span className="text-danger">*</span>
          </label>
          <select
            className="form-control"
            name="city"
            value={formData.city}
            onChange={handleChangeUser}
            disabled={!formData.state}
            required
          >
            <option value="">Select City</option>
            {cityList.map((city) => (
              <option key={city.name} value={city.name}>
                {city.name}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="d-flex justify-content-end mt-3 mb-5">
        <button className="btn btn-primary" type="submit" disabled={loading}>
          {loading ? (
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          ) : (
            "Submit"
          )}
        </button>
      </div>
      </form>
    </div>
  );
}
