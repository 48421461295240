import React, { useEffect, useState } from 'react';
// import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api'
import SearchBox from './SearchBox';

const libraries = ['places']

const MapComponent = ({selectedPosition,setSelectedPosition}) => {
    const [loading, setLoading] = useState(false);

  console.log(selectedPosition)

    // const getLocation = () => {
    //     if (navigator.geolocation) {
    //       navigator.geolocation.getCurrentPosition(
    //         (position) => {
    //           setSelectedPosition({
    //             lat: position.coords.latitude,
    //             lng: position.coords.longitude,
    //             // error: null,
    //           });
    //         },
    //         (error) => {
    //           setSelectedPosition({
    //             lat: null,
    //             lng: null,
    //             // error: error.message,
    //           });
    //         }
    //       );
    //     } else {
    //       setSelectedPosition({
    //         lat: null,
    //         lng: null,
    //         // error: "Geolocation is not supported by this browser.",
    //       });
    //     }
    //   };
    
    //   // Optional: Automatically get location on component mount
    //   useEffect(() => {
    //     getLocation();
    //   }, []);


  


    return (
        <>
            {/* <SearchBox setSelectedPosition={setSelectedPosition} setinnerData={setinnerData} /> */}
            {loading && <div>Loading map...</div>}
            {selectedPosition?.lat && selectedPosition?.lng ? <Map latitude={selectedPosition?.lat} longitude={selectedPosition?.lng} /> : ''}
        </>
    );
};

export default MapComponent;


const containerStyle = {
  width: '100%',
  height: '400px'
};

function Map({ latitude, longitude }) {

  const { isLoaded } = useJsApiLoader({
      googleMapsApiKey: 'AIzaSyCKodpnQoon5aC5csg7IXgFKD2gETOl0HA',
  });

  const center = {
      lat: latitude,
      lng: longitude,
  };

  if (!isLoaded) {
      return <div>Loading...</div>;
  }

  return (
          <GoogleMap
              mapContainerStyle={containerStyle}
              center={center}
              zoom={15}
          >
              <Marker position={center} />
          </GoogleMap>
  );
}
