import { BrowserRouter } from 'react-router-dom';
import './App.css';
import Index from './Routes/Index';
import React, { useEffect } from 'react';
import { Toaster } from "react-hot-toast";

// function loadGoogleMapsScript(apiKey, callbackName) {
//   const script = document.createElement("script");
//   script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places&callback=${callbackName}`;
//   script.async = true;
//   document.body.appendChild(script);
// }

function App() {

 

  // useEffect(() => {
  //   loadGoogleMapsScript("AIzaSyCloFlxsLYW2fYowdLLwu7K7XaJz0mMrxQ", "initMap");
  // }, []);
  return (
    <React.Fragment>
      <BrowserRouter>
        {/* router  */}
        <Index />
      </BrowserRouter>
      <Toaster
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </React.Fragment>
  );
}

export default App;
