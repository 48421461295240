import React from 'react'

export default function PaymentBookNow() {
  return (
    <div className='container'>
      <div className='main_container_payment_now'>
        
      </div>
    </div>
  )
}
