export let baseUrl = "https://eyemastr-c192ac1e2d63.herokuapp.com/api/";

export const API = {
  Allapi: {
    professionalSignup: "user/register",
    professionalLogin: "user/login",
    uploadImg: "uploadTo/uploadDocuments",
    deleteImg: "uploadTo/deleteMultipleDocData",
    profileData: "user/editProfessional",
    addOTP: "user/sendOtp",
    verifyOTP: "user/verifyMobileOtp",
    professionalView: "user/details",
    userData: "user/userById/",
    paymentData: "payment/addPayment/",
    doctorListing:"user/all-doctors",
    doctorsDetail:"user/userById/",
    getavailability:"availability/slots",
    addAppointment:"appointment/addAppointment",
    AddOtpUser:"user/signUp/signIn",
    verifyOtpUser:"user/verifyOtp",
    CompleteUserProfile:"user/edit-user",
  },
};