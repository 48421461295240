import React, { useState, useEffect } from 'react'
import { deleteImg, professionalView, profileData, uploadImg } from '../../Services/AllApi';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import style from './style.module.css'
import { Modal, ModalBody, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { FaStar } from "react-icons/fa6";
import { FaRegStar } from "react-icons/fa";
import { IoIosArrowForward } from "react-icons/io";
import { FaRegStarHalfStroke } from "react-icons/fa6";

import Loader from '../../Components/Loader/Loader';
import DocumentCard from '../../Components/DocumentCard/DocumentCard';
import MapComponent from '../../Components/MapComponents/MapComponent';

const ProfileView = () => {
    const userToken = JSON.parse(localStorage.getItem("UserDetail"));
    const [profileImage, setProfileImage] = useState([]);
    const [imgURl, setImgURl] = useState("")
    const [img, setImg] = useState("");
    const [delImg, setDelImg] = useState([]);
    const [check, setCheck] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showData, setShowData] = useState({})
    const [statusValue, setStatusValue] = useState('')
    const [activeTab, toggleTab] = useState('0')
    const [ratingData, setRatingData] = useState({
        count: '',
        avg: '',
        use_admin_rating: ''
    })
    const [isLoading, setIsLoading] = useState(true)
    const [isDisabled, setIsDisabled] = useState(true);
    const [selectedPosition, setSelectedPosition] = useState({
        lat: 40.7128,
        lng: -74.0060,
    });
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        name: "",
        prefix: "",
        pincode: "",
        city: "",
        state: "",
        country: "",
        address: "",
        eyetest: "",
        experience: "",
        organization: "",
        qualification: "",
        profession: "",
        speciality: "",
        company: "",
        lamp: false
    })

    const fetchData = async () => {
        const config = {
            headers: {
                Authorization: `Bearer ${userToken?.token}`
            }
        };
        try {
            setIsLoading(true);
            const response = await professionalView(config);
            let info = response?.data?.user;

            if (info) {

                let data = info || {}
                setShowData(data)
                setStatusValue(data?.profile_status)
                setRatingData({
                    count: data?.admin_rating?.count,
                    avg: data?.admin_rating?.avg,
                    use_admin_rating: data?.use_admin_rating
                })
                setSelectedPosition({
                    lat: +(data?.lat_long?.lat),
                    lng: +(data?.lat_long?.long),
                })
                setFormData({
                    name: info?.firstname || "",
                    prefix: info?.prefix || "",
                    pincode: info?.pinCode || "",
                    city: info?.city || "",
                    state: info?.state || "",
                    country: info?.country || "India",
                    address: info?.address || "",
                    eyetest: info?.perDayEyeTest || "",
                    experience: info?.yearsOfExperience || "",
                    organization: info?.organization || "",
                    qualification: info?.qualification || "",
                    profession: info?.profession || "",
                    speciality: info?.speciality || "",
                    company: info?.company || "",
                    lamp: info?.slitLampAvailable || false,
                });

                setProfileImage(info?.profile?.fileUrl);
                setDelImg([info?.profile?.fileName]);
                setImgURl(info?.profile?.fileName)
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }
    }


    useEffect(() => {
       
        fetchData();
    }, [])



    // const handleFileInput = (e) => {
    //     const file = e.target.files[0];

    //     if (file) {
    //         setImg(file);
    //         const imageUrl = URL.createObjectURL(file);
    //         setProfileImage(imageUrl);
    //         setCheck(true);
    //     }
    //     else {
    //         setCheck(false)
    //     }
    // };


    // const handleImage = async () => {
    //     if (!img) {
    //         return;
    //     }
    //     const formData = new FormData();
    //     formData.append('upload', img);
    //     try {
    //         const response = await uploadImg(formData);
    //         return response
    //     } catch (error) {
    //         console.log(error);
    //     }
    // }


    // const handleDelImage = async () => {
    //     let payload = {
    //         data: delImg
    //     };
    //     try {
    //         const response = await deleteImg(payload);
    //         setDelImg([]);
    //         console.log(response, "iamge deleting---")
    //         console.log()
    //     } catch (error) {
    //         console.log(error)
    //     }

    // }


    // const handleChange = (e) => {
    //     const { name, value } = e.target;
    //     console.log("Selected Value:", value);
    //     setFormData((prevData) => ({
    //         ...prevData,
    //         [name]: value,
    //     }));
    //     // if (name === 'state') {
    //     //     fetchCities(value);
    //     // }
    // };


    // const handleEdit = async (e) => {

    //     e.preventDefault();
    //     const config = {
    //         headers: {
    //             Authorization: `Bearer ${userToken?.token}`
    //         }
    //     };

    //     try {
    //         setLoading(true)
    //         if (check) {
    //             handleDelImage();
    //             const imageURl = await handleImage();
    //             console.log(imageURl, "image uploaded---")
    //             let fileName = imageURl?.data?.data[0]?.fileName;
    //             let fileUrl = imageURl?.data?.data[0]?.fileUrl;
    //             console.log(fileName, "filenmae");
    //             console.log(fileUrl, "fileurl");

    //             const payload = {
    //                 profile: {
    //                     fileName: fileName,
    //                     fileUrl: fileUrl
    //                 },
    //                 date_of_birth: "",
    //                 firstname: formData.name,
    //                 prefix: formData.prefix,
    //                 city: formData.city,
    //                 country: formData.country,
    //                 state: formData.state,
    //                 pinCode: formData.pincode,
    //                 speciality: formData.speciality,
    //                 profession: formData.profession,
    //                 qualification: formData.qualification,
    //                 company: formData.company,
    //                 yearsOfExperience: formData.experience,
    //                 perDayEyeTest: formData.eyetest,
    //                 slitLampAvailable: formData.lamp,
    //                 address: formData.address,
    //                 lat_long: {
    //                     lat: "",
    //                     long: ""
    //                 }
    //             }

    //             const response = await profileData(payload, config);
    //             console.log(response, "profile data");
    //             toast.success("Your Professional Profile has been Updated!")
    //             navigate("/profileView");
    //             setLoading(false)
    //             setCheck(false);
    //         }
    //         else {
    //             const payload = {
    //                 profile: {
    //                     fileName: imgURl,
    //                     fileUrl: profileImage
    //                 },
    //                 date_of_birth: "",
    //                 firstname: formData.name,
    //                 prefix: formData.prefix,
    //                 city: formData.city,
    //                 country: formData.country,
    //                 state: formData.state,
    //                 pinCode: formData.pincode,
    //                 speciality: formData.speciality,
    //                 profession: formData.profession,
    //                 qualification: formData.qualification,
    //                 company: formData.company,
    //                 yearsOfExperience: formData.experience,
    //                 perDayEyeTest: formData.eyetest,
    //                 slitLampAvailable: formData.lamp,
    //                 address: formData.address,
    //                 lat_long: {
    //                     lat: "",
    //                     long: ""
    //                 }
    //             }
    //             const response = await profileData(payload, config);
    //             console.log(response, "profile data");
    //             toast.success("Your Professional Profile has been Updated!")
    //             navigate("/profileView");
    //             setLoading(false);
    //             setCheck(false);
    //         }
    //     }
    //     catch (error) {
    //         setLoading(false)
    //         console.log(error)
    //     }
    // }

    function changeStatusUi(status) {
        switch (status) {
            case 'disapproved':
                return <div className="statusOuterin">
                    <div className="statusDotin" />
                    <div className="Statusin">Disapproved</div>
                </div>
                break;
            case 'under review':
                return <div className="statusOuterr">
                    <div className="statusDotr" />
                    <div className="Statusr">Under Review</div>
                </div>
                break;
            case 'approved':
                return <div className="statusOuterA">
                    <div className="statusDotA" />
                    <div className="StatusA">Approved</div>
                </div>
                break;

            default:
                break;
        }
    }



    return (
        <React.Fragment>
            {/* {isLoading ? <Loader open={isLoading} /> : ""} */}
          <div className='container mt-4'>
                <div className='d-flex justify-content-between'>
                    <div className='fs-5 fw-bold'>Profile Details</div>
                    {/* <button className='btn ps-0 d-flex align-items-center' onClick={() => navigate('/admin/professionals')}><IoArrowBack className='me-1' /> Back</button> */}
                    {changeStatusUi(showData?.profile_status)}
                </div>
                <div className='mt-4'>
                    <div className='d-flex align-items-center'>
                        <div className='p-relative'>
                            <div className={`${style.profileContainer}`}>
                                <img src={showData?.profile?.fileUrl == '' ? showData?.gender == 'male' ? '/images/maleProfile.jpg' : '/images/femaleProfile.jpg' : showData?.profile?.fileUrl} className='w-100 h-100' />
                            </div>
                        </div>
                        <div className='d-flex flex-column ms-md-4 ms-sm-3 ms-3'>
                            <div className='fs-md-3 fs-sm-4 fs-5 fw-bold pt-3 text-black text-capitalize'>{showData?.prefix} {showData?.firstname}</div>
                            <div className='text-secondary'>{showData?.email}</div>
                            <div className="d-flex align-items-center gap-3">
                                <div>
                                    <RatingStars averageRating={showData?.use_admin_rating ? showData?.admin_rating?.avg : showData?.averageRating} ratingCount={showData?.use_admin_rating ? showData?.admin_rating?.count : showData?.totalRatings} />
                                </div>
                                {/* <FaEdit className="fs-5 pointer" onClick={() => setRatingModal(true)} /> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`mt-4 ${style.tabContainer}`}>
                    <div className={`${style.left}`}>
                        <Nav vertical={false} className={`${true ? 'border-bottom flex flex-nowrap' : ''}`}>
                            <NavItem>
                                <NavLink
                                    className={`text-secondary fw-bold ${activeTab == "0" ? true ? 'activeTab2' : 'activeTab text-white ' : ""} ${true ? 'px-md-4 px-sm-3 px-2' : 'rounded ps-4 mt-2 py-2'} pointer`}
                                    onClick={() => toggleTab("0")}>
                                    <div className='d-flex justify-content-between align-items-center'><div>Profile</div> <IoIosArrowForward className='fw-600 d-md-none d-sm-none d-none' /></div>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={`text-secondary fw-bold ${activeTab == "1" ? true ? 'activeTab2' : 'activeTab text-white ' : ""} ${true ? 'px-md-4 px-sm-3 px-2' : 'rounded ps-4 mt-2 py-2'} pointer`}
                                    onClick={() => toggleTab("1")}>
                                    <div className='d-flex justify-content-between align-items-center'><div>Degree/Diploma</div> <IoIosArrowForward className='fw-600 d-md-none d-sm-none d-none' /></div>
                                </NavLink>
                            </NavItem>
                        </Nav>

                    </div>
                    <div className={`${style.right}`}>
                        <TabContent className="px-md-3 px-sm-2 px-1 pt-md-4 pt-sm-4 pt-3" activeTab={activeTab}>
                            <TabPane tabId="0">
                                <div className='d-flex justify-content-between align-items-center'>
                                    <div className="fs-4 fw-600">Profile</div>
                                    {/* <button className="btn btn-sm btn-primary" onClick={() => setStatusModal(true)}>Update Status</button> */}
                                </div>
                                <div className="row">
                                    <div className="col-md-6 mt-3">
                                        <label>Eye Care Organization</label>
                                        <input className="form-control" disabled value={showData?.company} />
                                    </div>
                                    <div className="col-md-6 mt-3">
                                        <label>Phone Number</label>
                                        <input className="form-control" disabled value={showData?.mobile} />
                                    </div>
                                    <div className="col-md-6 mt-3">
                                        <label>Specialities</label>
                                        <input className="form-control" disabled value={showData?.speciality?.join(', ')} />
                                    </div>
                                    <div className="col-md-6 mt-3">
                                        <label>Profession</label>
                                        <input className="form-control" disabled value={showData?.profession} />
                                    </div>
                                    <div className="col-md-6 mt-3">
                                        <label>Qualification</label>
                                        <input className="form-control" disabled value={showData?.qualification} />
                                    </div>
                                    <div className="col-md-6 mt-3">
                                        <label>Years of Experience</label>
                                        <input className="form-control" disabled value={showData?.yearsOfExperience} />
                                    </div>
                                    <div className="col-md-6 mt-3">
                                        <label>Per Day Eye Test in Current Practice</label>
                                        <input className="form-control" disabled value={showData?.perDayEyeTest} />
                                    </div>
                                    <div className="col-md-6 mt-3">
                                        <label>Slit Lamp Available</label>
                                        <input className="form-control" disabled value={showData?.slitLampAvailable ? 'Yes' : 'No'} />
                                    </div>
                                    <div className="col-md-6 mt-3">
                                        <label>Address</label>
                                        <input className="form-control" disabled value={`${showData?.city}, ${showData?.state}, ${showData?.country}`} />
                                    </div>
                                    <div className="col-md-6 mt-3">
                                        <label>Pin Code</label>
                                        <input className="form-control" disabled value={showData?.pinCode ? showData?.pinCode : 'NA'} />
                                    </div>
                                    <div className="col-12 mt-3 mb-5">
                                        <MapComponent
                                            selectedPosition={selectedPosition}
                                            setSelectedPosition={setSelectedPosition}
                                        />
                                    </div>
                                </div>
                            </TabPane>
                            <TabPane tabId="1">
                                <div>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <div className='fs-5 fw-600 text-black'>Degree/Diploma</div>
                                    </div>
                                   
                                    {showData?.degree_document?.length !== 0 ? <div className={`${style.docContainer} mt-4`}>{showData?.degree_document?.map((res, index) => <DocumentCard
                                        key={index}
                                        file={res}
                                    />
                                    )}</div>: <div className="text-center fs-5 fw-bold my-5 py-5">No document uploaded yet</div>}
                                    
                                </div>
                            </TabPane>
                        </TabContent>
                    </div>
                </div>
          </div>
        </React.Fragment>

    )
}

export default ProfileView

function RatingStars({ averageRating, ratingCount }) {
    const fullStars = Math.floor(averageRating);
    const hasHalfStar = averageRating % 1 !== 0;
    const emptyStars = 5 - fullStars - (hasHalfStar ? 1 : 0);

    const renderStars = () => {
        const stars = [];


        for (let i = 0; i < fullStars; i++) {
            stars.push(<FaStar color="#FFD700" size={18} key={`full-${i}`} />);
        }


        if (hasHalfStar) {
            stars.push(<FaRegStarHalfStroke color="#FFD700" size={18} key="half" />);
        }


        for (let i = 0; i < emptyStars; i++) {
            stars.push(<FaRegStar size={18} key={`empty-${i}`} />);
        }

        return stars;
    };

    return (
        <div>
            <div className="rating-stars">{renderStars()}</div>
            <div className="rating-count">({ratingCount} ratings)</div>
        </div>
    );
};