import React from "react";
import "./ShareYourConcern.css";
import { useNavigate } from "react-router-dom";

export default function Symptoms() {
  const navigation = useNavigate();

  // Parse and keep it as an array
  let getData = JSON.parse(sessionStorage.getItem("selectedConcerns"));

  // Check if getData is an array and handle it
  if (!Array.isArray(getData)) {
    console.error("getData is not an array");
    getData = []; // Fallback to an empty array if needed
  }

  return (
    <div className="app-container">
      <div className="">
        <button
          className="btn btn-light"
          onClick={() => {
            navigation("/Share-your-concerns");
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="pe-3"
            width="24"
            height="24"
            fill="currentColor"
            class="bi bi-arrow-left"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"
            />
          </svg>
         
        </button>
      </div>
      <div className="d-flex justify-content-center">
        <h5 className="symptoms_heading">Symptoms</h5>
      </div>
      <div className="symptom-buttons-symptoms mt-3">
        {getData.map((e, index) => (
          <button key={index} className="symptom-button-btn-symptoms">
            {e}
          </button>
        ))}
      </div>
      <div className="mt-4">
        <div className="your_probably d-flex justify-content-center">
          You probably have
        </div>
        <div className="mt-3 converence_insu d-flex justify-content-center">
          Astigmatism Or Convergence Insufficiency
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <button
          className="submit-button"
          onClick={() => {
            navigation("/near-me-eyemaster");
          }}
        >
          EyeMastr near me
        </button>
      </div>
    </div>
  );
}
