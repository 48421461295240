import React from "react";

export default function Disclaimer() {
  return (
    <div className="p-4 card">
      <h3>Disclaimer</h3>
      <div className="mt-3">
        Eyemastr holds the international copyrights of all images, texts,
        graphics, and data on this website,{" "}
        <a href="www.eyemastr.com" style={{ color: "blue" }}>
          www.eyemastr.com
        </a>
        .
      </div>
      <div className="mt-3">
        ✓ Each trademark, product name, company name, and logo displayed on{" "}
        <a href="www.eyemastr.com" style={{ color: "blue" }}>
          www.eyemastr.com
        </a>{" "}
        is the exclusive property of the respective brands. Any entity must
        obtain written consent from Eyemastr to copy, republish, alter, and
        distribute any of these intellectual properties.
      </div>
      <div className="mt-3">
        ✓ We cannot guarantee or warrant the accuracy of the color and
        description of the products. These may differ from what is displayed on
        <a href="www.eyemastr.com" style={{ color: "blue" }}>
          www.eyemastr.com
        </a>
        .
      </div>
      <div className="mt-3">
        ✓ The medical information on{" "}
        <a href="www.eyemastr.com" style={{ color: "blue" }}>
          www.eyemastr.com
        </a>{" "}
        is provided as a reference for customers. We recommend consulting an eye
        doctor regarding any problems with your eyes.
      </div>
      <div className="mt-3">
        ✓ You are liable for any risks associated with your use of{" "}
        <a href="www.eyemastr.com" style={{ color: "blue" }}>
          www.eyemastr.com
        </a>
        .
      </div>
      <div className="mt-3">
        ✓ You will be solely responsible for any actions taken in reliance upon
        the content of the website. Eyemastr is liable only for the purchase
        price of any item purchased by you from the website.
      </div>
      <div className="mt-3">
        ✓ In the event of any damage, personal injury, or death; lost profits,
        goodwill, data, or business interruption arising from the use of the
        website, Eyemastr will not be responsible.
      </div>
      <div className="mt-3">
        ✓ This agreement is governed by the laws of India. If any litigation
        arises out of this website, it shall be exclusively held in the courts
        of India.
      </div>
      <div className="mt-3">
        ✓ Any provision of this agreement found to be illegal or void will be
        eliminated; however, the remainder will continue to hold full force and
        effect.
      </div>
      <div className="mt-3">
        ✓ The ‘terms of use’ and policies, as well as the website, are
        constantly changed and updated. Users are recommended to review the site
        regularly for any updates and changes. Continuous users will be
        considered to have accepted these changes.
      </div>
      <div className="mt-3">
        Eyemastr will not be liable for any ignorance on your part.
      </div>
      <div className="mt-3">
        The following are the only official coordinates of our India registered
        office address :
      </div>
      <ul className="mt-3" style={{ paddingLeft: "30px" }}>
        <li>
          <b>Website:</b>
          <a href="www.eyemastr.com" style={{ color: "blue" }}>
            www.eyemastr.com
          </a>
        </li>
        <li>
          <b>Email:</b>
          <a
            href="support@eyemastr.com
"
            style={{ color: "blue" }}
          >
            support@eyemastr.com
          </a>
        </li>
        <li>
          <b>Phone Number: </b>+91 9654587757
        </li>
      </ul>
      <h6 className="mt-3">
        <b>Eyemastr Care Private Limited and Eyemastr Optical Trading LLC.</b>
      </h6>
    </div>
  );
}
