import React from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import Home from "../Pages/Home/Home";
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
import Shop from "../Pages/Shop/Shop";
import Product from "../Pages/Shop/Product";
import Cart from "../Pages/Cart/Cart";
import CheckOut from "../Pages/CheckOut/CheckOut";
import Professional from "../Pages/Auth/Professional";
import Login from "../Pages/Auth/Login";
import Profile from "../Pages/Auth/Profile";
import OTPVerify from "../Pages/Auth/OTPVerify";
import ProfileView from "../Pages/Auth/ProfileView";
import HomePage from "../Pages/Home/HomePage";
import PaymentPage from "../Pages/PaymentPage/PaymentPage";
import PaymentSuccess from "../Pages/PaymentPage/PaymentSuccess";
import TermsAndConditions from "../Pages/Policy/TermsAndConditions";
import PrivacyPolicy from "../Pages/Policy/PrivacyPolicy";
import RefundPolicy from "../Pages/Policy/RefundPolicy";
import Disclaimer from "../Pages/Policy/Disclaimer";
import ShareYourConcerns from "../Pages/BookNow/ShareYourConcerns";
import Symptoms from "../Pages/BookNow/Symptoms";
import NearMeEyemaster2 from "../Pages/BookNow/NearMeEyemaster2";
import ProfessionalDetail from "../Pages/BookNow/ProfessionalDetail";
import MakeAppoinment from "../Pages/BookNow/MakeAppoinment";
import SlotPicker from "../Pages/BookNow/SlotPicker";
import UserProfileView from "../Pages/Auth/UserProfileView";
import UserLogin from "../Pages/Auth/UserLogin";
import PaymentBookNow from "../Pages/BookNow/PaymentBookNow";
import PaymentAppointmentSuccess from "../Pages/PaymentPage/PaymentAppointmentSuccess";

export default function Index() {
  const userToken = JSON.parse(localStorage.getItem("UserDetail"));

  const navigate = useNavigate();

  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/shop" element={<Shop />} />
        <Route path="/shop/product" element={<Product />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/checkout" element={<CheckOut />} />
        <Route path="/signup" element={<Professional />} />
        <Route path="/login" element={<UserLogin />} />
        <Route path="/professional-login" element={<Login />} />


        <Route path="/verifyOTP" element={<OTPVerify />} />
        <Route path="/payment" element={<PaymentPage />} />
        <Route path="/success" element={<PaymentSuccess />} />
        <Route path="/appointment-success" element={<PaymentAppointmentSuccess />} />

        <Route
          path="/profileView"
          element={userToken?.token ? <ProfileView /> : <Navigate to="/signup" />}
        />
         <Route
          path="/professionalProfile"
          element={userToken?.token ? <Profile /> : <Navigate to="/signup" />}
        />

        <Route path="/termsandcondition" element={<TermsAndConditions />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/refundpolicy" element={<RefundPolicy />} />
        <Route path="/disclaimer" element={<Disclaimer />} />
        <Route path="/Share-your-concerns" element={<ShareYourConcerns />} />
        <Route path="/symptoms" element={<Symptoms />} />
        <Route path="/near-me-eyemaster" element={<NearMeEyemaster2 />} />
        {/* <Route path="/professional-detail/:id" element={<ProfessionalDetail />} /> */}
        <Route path="/make-appointment/:id" element={<MakeAppoinment />} />
        <Route path="/professional-detail/:id" element={<SlotPicker />} />
        <Route path="/user-profile" element={<UserProfileView />} />
        <Route path="/book-payment" element={<PaymentBookNow />} />






      </Routes>
      <Footer />
    </>
  );
}
