import React, { useState } from "react";
import { IoEyeOutline } from "react-icons/io5";
import { IoEyeOffOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import {
  addOTP,
  professionalSignup,
  verifyOTP,
  addUserOtpSignUp,
  userVerifyOtp,
} from "../../Services/AllApi";
import toast from "react-hot-toast";
import { FaStar } from "react-icons/fa";

const Professional = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    mobile: "",
    password: "",
    confirmPassword: "",
    countryCode: "+91",
  });

  const [checkRole, setCheckRole] = useState(true);
  const [formUserData, setFormUserData] = useState({
    mobile: "",
    countryCode: "+91",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (value === " ") {
      e.target.value = "";
    } else {
      setFormData((pre) => {
        return {
          ...pre,
          [name]: value,
        };
      });
    }
  };

  const handleChangeUser = (e) => {
    const { name, value } = e.target;
    if (value === " ") {
      e.target.value = "";
    } else {
      setFormUserData((pre) => {
        return {
          ...pre,
          [name]: value,
        };
      });
    }
  };

  const fullMobileNumber = `${formData.countryCode}${formData.mobile}`;

  const handleSignup = async (e) => {
    e.preventDefault();

    if (formData?.password !== formData?.confirmPassword) {
      toast.error("Confirm Password didn't match with password");
      return;
    }
    const fullMobileNumber = `${formData.countryCode}${formData.mobile}`;

    const payload = {
      email: formData.email,
      password: formData.confirmPassword,
      role: "professional",
      mobile: fullMobileNumber,
    };
    console.log(payload);
    const payload2 = {
      mobile: fullMobileNumber,
    };
    console.log(payload2, "payload2");
    const payload3 = {
      mobile: payload.mobile,
      otp: "0000",
    };

    console.log(payload3, "payload3");

    const payload4 = {
      email: formData.email,
      password: formData.confirmPassword,
      role: "professional",
      mobile: fullMobileNumber,
    };
    try {
      setLoading(true);
      const response = await addOTP(payload2);
      if (payload2.mobile.includes("+971")) {
        const response3 = await verifyOTP(payload3);
        console.log(response3);
        const response4 = await professionalSignup(payload4);
        const userInfo = {
          token: response4?.data?.token,
        };
        localStorage.setItem("UserDetail", JSON.stringify(userInfo));
        navigate("/professionalProfile");
      } else {
        toast.success("OTP has been sent on your Mobile Number");
        setFormData("");

        navigate("/verifyOTP", { state: { payload, checkRole } });
      }
      setLoading(false);
      setFormData({
        email: "",
        confirmPassword: "",
        mobile: "",
        password: "",
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
      const errorMessage = error?.response?.data?.message;
      toast.error(errorMessage);
    }
  };

  const handleUserSignIn = async (e) => {
    e.preventDefault();
    const fullMobileNumberUser = `${formUserData.countryCode}${formUserData.mobile}`;

    const payloadUser = { mobile: fullMobileNumberUser };
    const payloadUserVerify = {
      mobile: fullMobileNumberUser,
      otp: "0000",
    };
    setLoading(true);

    try {
      const response = await addUserOtpSignUp(payloadUser);
      if (payloadUser.mobile.includes("+971")) {
        const resverify = await userVerifyOtp(payloadUserVerify);

        const userToken = {
          token: resverify?.data?.token,
        };
        localStorage.setItem("UserDetail", JSON.stringify(userToken));
        if (resverify?.data?.userData?.firstname) {
          toast.success("login successfully");
          window.location.href = "https://eyemastr.com/";
        } else {
          navigate("/user-profile");
        }
      } else {
        toast.success("OTP has been sent on your Mobile Number");
        setFormUserData("");
        navigate("/verifyOTP", { state: { payloadUser, checkRole } });
      }
      console.log(response);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  return (
    <div className="registrationBanner p-md-5 p-3">
      <div className="container">
        <div className="row ">
          <div className="col-lg-6 col-md-10 col-12 px-md-3 px-2 py-4 rounded bg-white">
            <div className="heading me-3">
              Register As Eyemast
              <FaStar color="#007bff" />r
            </div>
            <div className="text-gray me-3">
              Fill in the registration data. It will take a couple of minutes.{" "}
            </div>
            <div className="sub_heading mt-3">Are You Professional ?</div>
            <div className="row">
              <div className="col-6">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="exampleRadios"
                    id="exampleRadios1"
                    checked={checkRole}
                    //   value={checkRole?"on":"off"}
                    onClick={(e) => {
                      setCheckRole(true);
                      console.log(e.target.value);
                    }}
                  />
                  <label class="form-check-label" for="exampleRadios1">
                    Yes
                  </label>
                </div>
              </div>
              <div className="col-6">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="exampleRadios"
                    id="exampleRadios1"
                    //   value={checkRole}
                    onClick={(e) => {
                      setCheckRole(false);
                      console.log(e.target.value);
                    }}
                  />
                  <label class="form-check-label" for="exampleRadios1">
                    No
                  </label>
                </div>
              </div>
            </div>

            {checkRole === true ? (
              <>
                <form onSubmit={handleSignup}>
                  <div className="col-12 form-group mt-3">
                    <label className="labelHeading">
                      Mobile Number<span className="text-danger">*</span>
                    </label>
                    <div className="input-group">
                      <select
                        name="countryCode"
                        style={{ maxWidth: "150px" }}
                        value={formData.countryCode}
                        className="form-select pointer"
                        onChange={handleChange}
                      >
                        <option value="+91" className="pointer">
                          IN(+91)
                        </option>
                        <option value="+971" className="pointer">
                          UAE(+971)
                        </option>
                      </select>
                      <input
                        type="number"
                        placeholder="Enter your Mobile Number"
                        required
                        name="mobile"
                        value={formData.mobile}
                        className="form-control"
                        onChange={handleChange}
                        // onInput={(e) => {
                        //     e.target.value = e.target.value.slice(0, 10);
                        // }}
                      />
                    </div>
                  </div>
                  <div className="col-12 form-group mt-3">
                    <label className="labelHeading">
                      Email<span className="text-danger">*</span>
                    </label>
                    <input
                      placeholder="Enter your Email"
                      required
                      className="form-control"
                      name="email"
                      type="email"
                      value={formData.email}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="col-12 form-group mt-3">
                    <label className="labelHeading">
                      Create Password<span className="text-danger">*</span>
                    </label>
                    <div className="input-group position-relative">
                      <input
                        type={showPassword ? "text" : "password"}
                        placeholder="Enter your Password"
                        required
                        name="password"
                        className="form-control py-2 rounded"
                        id="password"
                        value={formData.password}
                        onChange={handleChange}
                        style={{ paddingRight: "45px" }}
                      />
                      <span
                        className="position-absolute iconStyle"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <IoEyeOffOutline /> : <IoEyeOutline />}
                      </span>
                    </div>
                  </div>
                  <div className="col-12 form-group mt-3">
                    <label className="labelHeading">
                      Confirm Password<span className="text-danger">*</span>
                    </label>
                    <div className="input-group position-relative">
                      <input
                        type={showConfirmPassword ? "text" : "password"}
                        placeholder="Confirm your Password"
                        required
                        className="form-control py-2 rounded"
                        name="confirmPassword"
                        value={formData.confirmPassword}
                        onChange={handleChange}
                        style={{ paddingRight: "45px" }}
                      />
                      <span
                        className="position-absolute iconStyle"
                        onClick={() =>
                          setShowConfirmPassword(!showConfirmPassword)
                        }
                      >
                        {showConfirmPassword ? (
                          <IoEyeOffOutline />
                        ) : (
                          <IoEyeOutline />
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="col-12 mt-5">
                    <button
                      className="btn btn-primary w-100"
                      type="submit"
                      disabled={loading}
                    >
                      {loading ? (
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        "Sign Up"
                      )}
                    </button>
                  </div>
                  <div className="mb-5 mt-3 text-center">
                    Already have an account ?{" "}
                    <span
                      className="pointer"
                      onClick={() => {
                        navigate("/professional-login");
                      }}
                      style={{ color: "#007bff" }}
                    >
                      Sign In
                    </span>
                  </div>
                </form>
              </>
            ) : (
              <>
                <form onSubmit={handleUserSignIn}>
                  <div className="col-12 form-group mt-3">
                    <label className="labelHeading">
                      Mobile Number<span className="text-danger">*</span>
                    </label>
                    <div className="input-group">
                      <select
                        name="countryCode"
                        style={{ maxWidth: "150px" }}
                        value={formUserData.countryCode}
                        className="form-select pointer"
                        onChange={handleChangeUser}
                      >
                        <option value="+91" className="pointer">
                          IN(+91)
                        </option>
                        <option value="+971" className="pointer">
                          UAE(+971)
                        </option>
                      </select>
                      <input
                        type="number"
                        placeholder="Enter your Mobile Number"
                        required
                        name="mobile"
                        value={formUserData.mobile}
                        className="form-control"
                        onChange={handleChangeUser}
                        // onInput={(e) => {
                        //     e.target.value = e.target.value.slice(0, 10);
                        // }}
                      />
                    </div>
                  </div>
                  <div className="col-12 mt-5">
                    <button
                      className="btn btn-primary w-100"
                      type="submit"
                      disabled={loading}
                    >
                      {loading ? (
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        "Sign Up"
                      )}
                    </button>
                  </div>
                  <div className="mb-5 mt-3 text-center">
                    Already have an account ?{" "}
                    <span
                      className="pointer"
                      onClick={() => {
                        navigate("/login");
                      }}
                      style={{ color: "#007bff" }}
                    >
                      Sign in
                    </span>
                  </div>
                </form>
              </>
            )}

            {/* <div className='mb-5 mt-3 text-center'>
                                Already have an account ? <span className='pointer' onClick={() => { navigate("/login") }} style={{ color: '#007bff' }}>Login</span>
                            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Professional;
