import React from "react";

export default function RefundPolicy() {
  return (
    <div className="p-4 card">
      <h3>Refund policy</h3>
      <h6 className="mt-3">
        <b>1. RETURN, REFUND OR EXCHANGE POLICY</b>
      </h6>
      <div className="mt-3">
        Eyemastr provides You the opportunity for return, refund, or exchange on
        your purchase through the website, provided You meet the following
        conditions:
      </div>
      <ul className="mt-3" style={{ paddingLeft: "30px" }}>
        <li>
          (a) The request for return, exchange, or refund should be raised
          within 14 (fourteen) days of receiving the product(s) from our
          Website;
        </li>
        <li>
          (b) The price tag, any other identification tag, the invoice, and the
          original packaging must be intact and sent back;
        </li>
        <li>
          (c) The product must not have been used or show any signs of wear,
          dirt, scratches, or other damage rendering it unsellable;
        </li>
        <li>
          (d) You must include your order number, order date, and receipt date;
        </li>
        <li>
          (e) Ensure the return package is securely sealed, as any damage in
          transit will be Your responsibility, and this policy will no longer
          apply;
        </li>
        <li>
          (f) Our dedicated team will inspect the returned products, and their
          decision regarding your request will be final;
        </li>
        <li>
          (g) If Your request is accepted, we will refund an amount up to Rs.
          100 toward shipping costs or provide a voucher;
        </li>
        <li>
          (h) To request a return, refund, or exchange, please contact our
          customer care at [+91 9654587757] or email us at
          [support@eyemastr.com].
        </li>
      </ul>
      <h6 className="mt-3">
        <b>Additional Requirements:</b>
      </h6>
      <ul className="mt-3" style={{ paddingLeft: "30px" }}>
        <li>
          (a) For product packaging: Ensure all components such as beads,
          threads, zippers, buckles, etc., are intact. The packaging must be
          unwashed with no stains.
        </li>
        <li>
          (b) For lenses: No scratches must be present. Lenses should remain
          intact and be returned with the original box, instructions, guarantee,
          and any extras.
        </li>
        <li>
          (c) For glasses: Ensure no scratches or damage. Frames should not be
          bent, and screws must remain intact.
        </li>
      </ul>
      <div className="mt-3">
        The refund, return, or exchange process will be initiated once the
        product(s) are received in the condition described above. You may
        receive a refund, a voucher, or an exchange for a product of equal or
        higher value. No price difference will be refunded for lower-value
        exchanges.
      </div>
      <div className="mt-3">
        Refunds are processed within 30 (thirty) working days, though additional
        time may be required for Cheque or Demand Draft payments. Any warranties
        from original manufacturers will be applicable, subject to Your
        compliance with the terms. Eyemastr will not be responsible for the
        warranties offered by third-party manufacturers.
      </div>
      <div className="mt-3">
        This policy applies only to customers in India. International customers
        will not be eligible for returns, refunds, or exchanges due to
        prohibitive shipping costs. No refunds will be provided for Cash on
        Delivery orders.
      </div>
      <h6 className="mt-3">
        <b>2. SECURITY AND PRIVACY</b>
      </h6>
      <div className="mt-3">
        At Eyemastr, we value your trust and take your privacy seriously. This
        notice explains our privacy practices. By visiting this Website, You
        accept the practices described in this Privacy Policy.
      </div>
      <ul className="mt-3" style={{ paddingLeft: "30px" }}>
        <li>
          <b>(a) Information You Provide: </b>We collect information you enter
          on our website or provide to us in other ways. However, we do not
          store sensitive personal information on our servers. You may choose
          not to provide certain information, but doing so may limit your
          ability to take advantage of certain features.
        </li>
        <li>
          <b>(b) Automatic Information:</b>
          We receive and store information, such as IP addresses, when You
          interact with our services.
        </li>
        <li>
          <b>(c) Email Communications:</b>To enhance user experience, we may
          request confirmation when You open our emails if your device supports
          it.
        </li>
        <li>
          <b>(d) Sharing Information:</b>We may share customer information with
          partner businesses when necessary to complete transactions.
        </li>
        <li>
          <b>(e) Business Transfers: </b>In the event of a merger or
          acquisition, customer information may be one of the transferred
          assets. You will be notified of such changes and your information will
          remain protected.
        </li>
        <li>
          <b>(f) Legal Compliance:</b>We may release information as required to
          comply with legal requests or to protect the rights, property, or
          safety of Eyemastr, our users, or others.
        </li>
      </ul>
      <div className="mt-3">
        Eyemastr employs Secure Sockets Layer (SSL) technology to protect your
        information during transmission. We continuously review and enhance our
        privacy and security practices to address emerging challenges.
      </div>
      <h6 className="mt-3">
        <b>3. SECURITY OF INFORMATION</b>
      </h6>
      <div className="mt-3">
        We utilise SSL encryption to protect your data during transmission and
        constantly re-evaluate our security practices. Eyemastr does not sell or
        rent your personal information, except in cases where:
      </div>
      <ul className="mt-3" style={{ paddingLeft: "30px" }}>
        <li>(i) a valid legal request is made by law enforcement,</li>
        <li>(ii) you provide consent, or</li>
        <li>(iii) we use anonymized data for statistical purposes.</li>
      </ul>
      <div className="mt-3">
        Your personal account information is strictly accessible to Eyemastr
        employees and contractors on a need-to-know basis. Protect your login
        credentials and sign off after using shared computers. Any disputes or
        privacy concerns can be addressed to: India registered office address
      </div>
      <ul className="mt-3" style={{ paddingLeft: "30px" }}>
        <li>
          <b>Website: </b>
          <a href="www.eyemastr.com" style={{ color: "blue" }}>
            www.eyemastr.com
          </a>
        </li>
        <li>
          <b>Email: </b>
          <a
            href="support@eyemastr.com
"
            style={{ color: "blue" }}
          >
            support@eyemastr.com
          </a>
        </li>
        <li>
          <b>Phone Number: </b>+91 9654587757
        </li>
      </ul>
    </div>
  );
}
