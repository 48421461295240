import React from "react";

export default function TermsAndConditions() {
  return (
    <div className="p-4 card">
      <h3>Terms & Condition</h3>
      <div className="mt-3">
        “Eyemastr Care Private Limited and Eyemastr Optical Trading LLC”, a
        private limited company duly incorporated under the provisions of the
        Companies Act, 1956 (hereinafter referred to as ‘Company’) and having
        its registered office at [India address]. The Company is an
        internet-based content and e-commerce portal. It has under a Licence
        Agreement entered with Eyemastr Solutions Private Limited been licensed
        to utilise the following official websites owned by Eyemastr Solutions
        Private Limited, www.eyemastr.com (hereinafter referred to as ‘Website’
        singularly and as ‘Websites’ jointly) for its e-commerce activities.
        This Agreement for the Terms and Conditions of use of the Company’s
        Website/s (hereinafter referred to as ‘Agreement’) describes certain
        terms and conditions to access and use the Website/s of the Company by a
        visitor or a registered user and order products (hereinafter referred to
        as the “End User”, “You” or “Your”).
      </div>
      <div className="mt-4">
        <h5>
          <b>1. Policy for NDNC - National Do Not Call.</b>
        </h5>
        <div className="mt-3">
          By using the website and/or registering yourself at Eyemastr.com, you
          authorise us to contact you via email or phone call or SMS and offer
          you our services, impart product knowledge, offer promotional offers
          running on the website & offers offered by the associated third
          parties, for which reasons, personally identifiable information may be
          collected. Regardless of whether you have registered under DND, DNC,
          or NCPR service, you still authorise us to contact you for the
          above-mentioned purposes till 45 days after your registration with us.
        </div>
        <div className="mt-3">
          This Privacy Policy covers Eyemastr’s treatment of personally
          identifiable information that Eyemastr collects when you are on the
          Eyemastr site, and when you use Eyemastr’s services. It also covers
          the treatment of any personally identifiable information that
          Eyemastr’s business partners share with Eyemastr. This policy does not
          apply to the practices of companies that Eyemastr does not own or
          control or to people Eyemastr does not employ or manage.
        </div>
        <div className="mt-3">
          When you register with Eyemastr, we ask for your first name, last
          name, contact number, email, date of birth, and gender. Once you
          register with Eyemastr and sign in to our services, you are not
          anonymous to us. During registration, you may be requested to register
          your mobile phone and email ID, pager, or other devices to receive
          text messages, notifications, and other services to your wireless
          device. By registering, you authorise us to send SMS/email alerts to
          you for your login details and other service requirements or some
          advertising messages/emails from us.
        </div>
        <div className="mt-3">
          If you do not agree to these terms and conditions, the terms and
          conditions attached to any sale, purchase, warranty, use of our
          website and/or mobile application, the purchase of any products or
          services through the website and/or the mobile application is
          prohibited. Continued use and access of the website, mobile
          application, any purchases, and the services shall be deemed as the
          acceptance of our terms and conditions, policies, and their potential
          changes.
        </div>
      </div>
      <div className="mt-4">
        <h5>
          <b>2. ACCEPTANCE OF TERMS OF USE</b>
        </h5>
        <div className="mt-3">
          PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY. BY ACCESSING THIS
          WEBSITE YOU AGREE TO BE BOUND BY THE TERMS AND CONDITIONS BELOW. THE
          COMPANY RESERVES THE RIGHT TO ALTER, AMEND, AND MODIFY THESE TERMS AND
          CONDITIONS AT ITS SOLE DISCRETION. ALL SUCH AMENDMENTS AND
          MODIFICATIONS WILL BE DULY NOTIFIED ON THIS WEBSITE. IF YOU DO NOT
          AGREE TO THE TERMS AND CONDITIONS, WE REQUEST YOU NOT TO ACCESS THIS
          WEBSITE.
        </div>
        <div className="mt-3">
          You expressly agree to be bound by this Agreement, whether you are a
          registered member or a visitor, by using the information, tools,
          features, and functionality located on this Website. The Company
          recommends that you read all the terms and conditions provided herein,
          whether you are a Visitor or a Registered Member. You may save the
          terms and conditions for future reference or print them for your
          records.
        </div>
        <div className="mt-3">
          The Services provided or offered by this Website are intended only for
          individuals/entities permitted to enter and be bound by a legal
          contract under the law. If you are not legally permitted to enter into
          a contract, due to age or other reasons, you are not permitted to
          utilise the Services of this Website or enter this Agreement.
          Alternatively, you may order products under parental supervision.
        </div>
        <div className="mt-3">
          By accepting this Agreement and utilising the Services, you represent
          that you are of the legal contracting age or are operating under
          parental supervision. You also agree that if you represent an
          individual, company, third party, or entity, you have the valid
          authority and right to bind them to this Agreement.
        </div>
        <div className="mt-3">
          The Services available on this Website are for the specific purpose of
          enabling Users to purchase original merchandise such as eyewear
          (including spectacles and contact lenses), watches, and bags from
          high-end brands (collectively, "Products"). It is not intended to
          provide certification, guarantee, warranty, or professional advice.
          You agree and acknowledge that your use of the Website is for
          information and guidance purposes only.
        </div>
        <div className="mt-3">
          Unauthorised use of our systems or this Website, including
          unauthorised entry into our systems, misuse of passwords, or misuse of
          information posted, is strictly prohibited.
        </div>
        <div className="mt-3">
          You acknowledge that Eyemastr may disclose and transfer any
          information you provide through this Website to affiliates,
          information providers, third parties with your permission, or if
          required by law. You consent to the transmission, transfer, or
          processing of such information globally and agree to such transfers.
        </div>
        <div className="mt-3">
          You agree that your use of the Website may be monitored, tracked, and
          recorded. You are responsible for being familiar with the current
          version of these Terms and Conditions posted on the Website during
          each session. Continued use of the Website implies agreement to any
          revisions posted.
        </div>
      </div>
      <div className="mt-4">
        <h5>
          <b>3. General Terms and Conditions</b>
        </h5>
        <div className="mt-3">
          <ul className="" style={{ paddingLeft: "30px" }}>
            <li>
              Returns and exchanges of products will be accepted within 14 days
              from the date of delivery.
            </li>
            <li>
              By participating in offers, the customer confirms they have
              reviewed and agree to be bound by the terms.
            </li>
            <li>
              Every person availing of an offer does so after independent
              inquiry, investigation, and analysis.
            </li>
            <li>
              Eyemastr reserves the right to amend or modify these terms
              (including price) without prior notice.
            </li>
            <li>
              These T&C’s are governed by the laws of India. All disputes will
              be settled in the exclusive jurisdiction of the courts.
            </li>
            <li>
              Product images shown under offers are for illustration purposes
              and may not exactly represent the product.
            </li>
          </ul>
          
        </div>
      </div>
      <div className="mt-4">
        <h5>
          <b>4. T&C FOR EYEMASTR CASH and EYEMASTR CASH+</b>
        </h5>
        <h6 className="mt-3">
          <b>Eyemastr Cash Terms</b>
        </h6>
        <div className="mt-3">
          These are the general terms for the discount provided under Eyemastr
          Cash, and specific terms for any offer will be mentioned under product
          listings or shared separately.
        </div>
        <ul className="mt-3" style={{ paddingLeft: "30px" }}>
          <li>Valid once per user during the offer period.</li>
          <li>
            Eyemastr Cash+ provides additional benefits, including a 10%
            discount on the first purchase and 5% on subsequent purchases until
            the expiration of Gold Membership.
          </li>
          <li>The maximum limit for Eyemastr Cash+ redemption is INR 5,000.</li>
        </ul>
        <h6 className="mt-3">
          <b>Eyemastr Cash and Eyemastr Cash + Discount Redemption Process</b>
        </h6>
        <h6 className="mt-3">
          <b>Online purchases:</b>
        </h6>
        <div className="mt-3">
          1. Login to Eyemastr.com (Mobile App/Mobile Site/Website).
        </div>
        <div className="mt-3">2. Add eligible products to the cart.</div>
        <div className="mt-3">3. Proceed to checkout.</div>
        <div className="mt-3">
          4. Apply Eyemastr Cash/ Cash+ on the payments page.
        </div>
        <div className="mt-3">
          5. Complete your transaction using the available options.
        </div>
        <h6 className="mt-3">
          <b>Offline purchases:</b>
        </h6>
        <ul className="mt-3" style={{ paddingLeft: "30px" }}>
          <li>
            Apply the coupon code on eligible products during billing and
            checkout.
          </li>
        </ul>
        <div className="mt-3">
          Where should I contact in case I didn't receive the cashback after an
          eligible purchase?
        </div>
        <div className="mt-3">
          If you are eligible for the Eyemastr Cash/Cash+ offer as per the order
          summary page and you do not receive the same, please contact Eyemastr
          customer care from the help section on the Eyemastr application and/or
          website.
        </div>
        <h6 className="mt-3">
          <b>Eyemastr Cash and Eyemastr Cash+ eligible users</b>
        </h6>
        <div className="mt-3">
          The offer is open to all residents of India holding a valid and active
          Eyemastr account. Other terms, conditions, and policies of Eyemastr
          apply to the offer/Eyemastr Cash/Eyemastr Cash+.
        </div>
        <div className="mt-3">
          By participating in the offer on any eligible sale for Cash/Cash+,
          Customers agree to be bound by:
        </div>
        <ul className="mt-3" style={{ paddingLeft: "30px" }}>
          <li>These terms and conditions (T&C);</li>
          <li>
            The terms of use, privacy policy, and other relevant documentation
            including any modifications, alterations, or updates thereof, that
            are published on the platform, website, and our application;
          </li>
          <li>Warranty terms and conditions;</li>
          <li>All applicable terms and conditions of Eyemastr; and</li>
          <li>Offer specific details/terms and conditions.</li>
        </ul>
        <div className="mt-3">
          This offer is also subject to the following conditions:
        </div>
        <div className="mt-3" style={{ paddingLeft: "30px" }}>
          1. This offer is only applicable on transactions made through payment
          options made available on our website/stores.
        </div>
        <div className="mt-3" style={{ paddingLeft: "30px" }}>
          2. In case of cancellation of your order, the transaction will not
          qualify for the offer.
        </div>
        <div className="mt-3" style={{ paddingLeft: "30px" }}>
          3. Eyemastr reserves the right to disqualify the user(s) from the
          benefits of the offer if any fraudulent activity is identified as
          being carried out for availing the benefits under the said offer or
          otherwise by use of the account.
        </div>
        <div className="mt-3" style={{ paddingLeft: "30px" }}>
          4. Any person availing of this offer shall be deemed to have accepted
          these terms and conditions.
        </div>
        <div className="mt-3" style={{ paddingLeft: "30px" }}>
          5. Eyemastr reserves the right, at any time, without prior notice and
          without assigning any reason whatsoever, to add/alter/modify/change or
          vary these terms and conditions or to replace, wholly or in part, this
          offer by another offer, whether similar to this offer or not, or to
          extend or withdraw it altogether.
        </div>
        <div className="mt-3" style={{ paddingLeft: "30px" }}>
          6. Users are not bound in any way to participate in this offer. Any
          participation is voluntary, and the offer is being made purely on a
          best-effort basis.
        </div>
        <div className="mt-3" style={{ paddingLeft: "30px" }}>
          7. Nothing herein amounts to a commitment by Eyemastr to conduct
          further, similar, or other offers.
        </div>
        <div className="mt-3" style={{ paddingLeft: "30px" }}>
          8. The above offer is by way of a special offer for Eyemastr
          registered users only and nothing contained herein shall prejudice or
          affect the terms and conditions of the Eyemastr terms and conditions
          of use and privacy policy. The terms of the above schemes/offers shall
          be in addition to and not in derogation of the terms contained in the
          Eyemastr terms and conditions of use and privacy policy.
        </div>
        <div className="mt-3" style={{ paddingLeft: "30px" }}>
          9. You, the user, understand that Eyemastr may use your transactional
          data for purposes of this offer and for checking your eligibility for
          their products and services, and by participating in this offer, you
          consent to this sharing and use of your data for these purposes.
        </div>
        <div className="mt-3" style={{ paddingLeft: "30px" }}>
          10. Under no circumstance will the offer/cashback being offered be
          settled with cash in lieu by Eyemastr.
        </div>
        <div className="mt-3" style={{ paddingLeft: "30px" }}>
          11. Returned transactions, disputed or unauthorised/fraudulent
          transactions will not be considered for the offer.
        </div>
        <div className="mt-3" style={{ paddingLeft: "30px" }}>
          12. This offer cannot be combined with other offers including any
          other discount/coupon code.
        </div>
        <div className="mt-3" style={{ paddingLeft: "30px" }}>
          13. Either party shall not be liable for any failure for any technical
          issues attributable to either party because of which the transaction
          made by the user was not successful. Parties shall mutually resolve
          the issues pertaining to their respective breaches. Further, each
          party shall solely be liable to the user for wrongful acts/omissions
          attributable to them.
        </div>
        <div className="mt-3" style={{ paddingLeft: "30px" }}>
          14. The user shall indemnify and hold Eyemastr harmless against all
          damages, liabilities, costs, expenses, claims, suits, and proceedings
          (including reasonable attorney's fees) that may be suffered by
          Eyemastr as a consequence of (i) violation of these terms and
          conditions, (ii) violation of the terms of the user agreement, privacy
          policy (subject to change) published on the platform, by the user,
          (iii) violation of applicable laws by the user, and (iv) any action or
          inaction resulting in willful misconduct or negligence on the part of
          the user.
        </div>
        <div className="mt-3" style={{ paddingLeft: "30px" }}>
          15. All government levies like sales tax, goods and services taxes,
          tax deducted at source, any local tax, octroi, etc. shall be payable
          by the user as applicable at the time of the respective offer.
        </div>
        <div className="mt-3" style={{ paddingLeft: "30px" }}>
          16. This offer shall be subject to all applicable laws, rules, and
          regulations which are in existence and which may be promulgated
          anytime by any statutory authority.
        </div>
        <div className="mt-3" style={{ paddingLeft: "30px" }}>
          17. This offer is valid only in India. The minimum age of such a user
          shall be 18 years.
        </div>
        <div className="mt-3" style={{ paddingLeft: "30px" }}>
          18. This document is an electronic record in terms of the Information
          Technology Act, 2000, and the Rules thereunder as applicable and the
          amended provisions pertaining to electronic records in various
          statutes as amended by the Information Technology Act, 2000. This
          electronic record is generated by a computer system and does not
          require any physical or digital signatures.
        </div>
        <div className="mt-3" style={{ paddingLeft: "30px" }}>
          19. We reserve the right, at any time, without prior notice and
          without assigning any reason whatsoever, to add/alter/modify/change or
          vary these terms and conditions, the website, mobile applications, our
          general terms and conditions of use, and/or the policies listed in
          this document, or to replace, wholly or in part, an offer by another
          offer, whether similar to this offer or not, or to extend or withdraw
          it altogether.
        </div>
        <div className="mt-3" style={{ paddingLeft: "30px" }}>
          20. The customer’s right to avail the benefits under the Offer shall
          continue until such time that Eyemastr, at its sole discretion and
          without the obligation of a notice, terminates the offer. The Customer
          may terminate these at any time by uninstalling the Eyemastr App.
          These Terms will automatically terminate if the Customer fails
          substantially to comply with them. Upon termination by the Customer,
          or by Eyemastr due to the Customer’s breach of these terms, the
          Customer may not be entitled to any outstanding benefits of the offer.
        </div>
        <div className="mt-3">
          These terms and conditions must be read in conjunction with the other
          terms and conditions of the usage of the Eyemastr App and/or
          Eyemastr's website available at https://eyemastr.com.
        </div>
      </div>
      <div className="mt-4">
        <h5>
          <b>5. DESCRIPTION OF SERVICE AND PRODUCTS</b>
        </h5>
        <div className="mt-3">
          Eyemastr provides you with the opportunity to purchase eyewear and
          related products from top-tier manufacturers and retailers. Upon
          placing an order, Eyemastr will ship the product to you, and you will
          be obligated to make the payment for the services provided. All
          products and related information displayed on the website constitute
          an "invitation to offer." When you place an order, it represents your
          "offer," which is subject to the terms and conditions outlined in this
          agreement. Eyemastr reserves the right to accept or reject your offer
          at its sole discretion, which cannot be contested by you.
        </div>
        <div className="mt-3">
          Eyemastr strives to display the colours of the products as accurately
          as possible, but the colours seen by you may vary depending on your
          monitor, and no guarantee is provided regarding the accuracy of these
          colours. Additionally, while every effort is made to ensure that
          product descriptions are accurate, complete, and up-to-date, Eyemastr
          does not guarantee that the descriptions are error-free. If a product
          does not match its description, your sole remedy is to return it in
          unused, unsoiled, and resale condition.
        </div>
        <div className="mt-3">Eyemastr currently offers the following:</div>
        <ul className="mt-3" style={{ paddingLeft: "30px" }}>
          <li>Eyewear available through its website www.eyemastr.com.</li>
          <li>
            A wide selection of contact lenses and related products, along with
            information designed to help guide you in making informed purchasing
            decisions.
          </li>
        </ul>
        <div className="mt-3">
          The ranking of products on the platform is based on factors such as
          launch date and popularity. Newly launched products may appear higher
          in the rankings, while products that are frequently purchased by
          customers will also rank higher compared to less popular items. The
          final ranking is determined by a combination of these two factors.
        </div>
      </div>
      <div className="mt-4">
        <h5>
          <b>6. YOUR OBLIGATION AND COVENANTS TOWARDS THE PRODUCTS</b>
        </h5>
        <div className="mt-3">
          In addition to your other obligations under this agreement, by
          ordering products from Eyemastr, you acknowledge and agree to the
          following:
        </div>
        <ul className="mt-3" style={{ paddingLeft: "30px" }}>
          <li>
            You have a valid and written prescription from a certified doctor
            for prescription glasses or lenses, and the information you provide
            to Eyemastr will be accurate.
          </li>
          <li>
            You consent to Eyemastr contacting your doctor to verify the
            authenticity of your prescription if required.
          </li>
          <li>
            If any non-delivery or incorrect delivery of products occurs due to
            an error in the information provided by you, any costs incurred for
            redelivery, in addition to the initial cost, will be billed to you.
          </li>
          <li>
            All personal information provided by you, including contact details,
            name, address, and payment details, is accurate, authentic, and free
            from fraudulent activity.
          </li>
          <li>
            Before placing an order, you will carefully review the product
            description. By placing an order, you agree to be bound by the terms
            outlined in the product’s description.
          </li>
        </ul>
      </div>
      <div className="mt-4">
        <h5>
          <b>7. INFORMATION REQUIRED FROM YOU</b>
        </h5>
        <div className="mt-3">
          Upon your acceptance to become a member and use the services provided
          by Eyemastr, you will be required to provide basic information for
          registration. Once registered, additional information may be requested
          to offer you the full range of services. However, Eyemastr will not
          request sensitive personal data unless necessary. All information
          provided by you will be treated as confidential, as outlined in the
          Privacy Policy and Security clauses.
        </div>
      </div>
      <div className="mt-4">
        <h5>
          <b>8. ORDER FOR AND SUPPLY OF PRODUCTS</b>
        </h5>
        <div className="mt-3">
          You will need to submit your order for the purchase of any product as
          detailed on the website, or you can email us at [support@eyemastr.com]
          or contact us at [+91 9654587757].
        </div>
        <div className="mt-3">
          Once an order is submitted, it constitutes your express intention to
          purchase the product, and it may not be cancelled except as provided
          under this agreement. Based on the information you provide and subject
          to verification, Eyemastr will process your order. All orders will be
          processed after Eyemastr receives payment for the product.
        </div>
        <div className="mt-3">
          You acknowledge that for prescription glasses, Eyemastr reserves the
          right to verify your prescription with your prescribing doctor or an
          independent qualified optician. The acceptance of your order is
          subject to such verification.
        </div>
        <div className="mt-3">
          All orders will be confirmed via email. Eyemastr will use its best
          efforts to ensure that your order is processed successfully, subject
          to product availability. If the product is sold out or unavailable,
          Eyemastr will inform you, and you may either receive a refund or
          choose a product of the same value.
        </div>
      </div>
      <div className="mt-4">
        <h5>
          <b>9. PRICING AND PAYMENT</b>
        </h5>
        <div className="">
          (a) The prices for products are described on the website and are
          incorporated into these terms by reference. All prices are in [Insert
          Currency]. The prices, products, and services are subject to change at
          Eyemastr’s discretion.
        </div>
        <div className="">(b) All prices include applicable taxes.</div>
        <div className="">
          (c) Eyemastr strives to ensure price accuracy on the website. However,
          Eyemastr does not guarantee that the prices on the website will match
          prices offered by physical stores.
        </div>
        <div className="">
          (d) In the event of a pricing error, Eyemastr will notify you before
          your payment is processed, allowing you to either accept the correct
          price or cancel your order.
        </div>
        <div className="">
          (e) Eyemastr accepts the following forms of payment:
        </div>
        <div className="">
          i. Online payments via credit card, debit card, net banking, and
          PayPal.
        </div>
        <div className="">
          ii. Cash on Delivery in limited locations (subject to the
          return/refund policy).
        </div>
        <div className="">iii. Cash deposit into Eyemastr's bank account.</div>
        <div>
          (f) You confirm that the payment details provided are correct and
          belong to you. If you use a third-party card, you confirm that you
          have authorization from the cardholder to use it.
        </div>
        <div>
          (g) Eyemastr will not be held liable for any credit card fraud. The
          liability for fraudulent use of a card will rest with you.
        </div>
        <div>
          (h) Eyemastr reserves the right to recover costs, legal fees, or other
          charges if fraudulent activity is detected.
        </div>
        <div>
          (i) Eyemastr does not charge any additional fees for payments. If
          payment gateway fees are applicable, these will be communicated during
          the transaction.
        </div>
      </div>
      <div className="mt-4">
        <h5>
          <b>10. DELIVERY AND CANCELLATION</b>
        </h5>
        <div className="">
          (a) Eyemastr disclaims any guarantee regarding the exact appearance of
          the final product, as it may differ from the on-screen display. If
          there is a discrepancy with your order, Eyemastr will notify you. If
          you disagree with the changes, Eyemastr will either offer you a full
          refund or a voucher for an alternative product of the same value.
        </div>
        <div className="">
          (b) The title and risk of loss for all products pass to you upon
          shipment to the carrier.
        </div>
        <div className="">
          (c) Eyemastr will make the delivery on a "best effort" basis. Products
          are usually shipped within 7 days of order acceptance, subject to
          availability and unforeseen circumstances.
        </div>
        <div className="">
          (d) Eyemastr will provide shipping details, and you must notify us if
          you do not receive the product within a reasonable time.
        </div>
        <div className="">
          (e) You are obligated to check the product for accuracy upon receipt.
        </div>
        <div className="">
          (f) Eyemastr reserves the right to cancel an order due to
          non-availability, errors in product or pricing information, or any
          fraudulent activity detected. Cancellations will be communicated to
          you, and a refund will be provided.
        </div>
        <div className="">
          (g) If you wish to cancel an order, it must be done before the product
          is dispatched. Once dispatched, cancellations will not be accepted.
        </div>
      </div>
      <div className="mt-4">
        <h5>
          <b>11. LINKS TO THIRD-PARTY SITES</b>
        </h5>
        <div className="mt-3">
          While using Eyemastr’s services, you may provide personal information
          for retrieving or collecting data from third-party websites. These
          third-party websites may belong to financial institutions or other
          entities where you hold accounts. Eyemastr does not assume
          responsibility for the accuracy or completeness of data maintained by
          such third parties.
        </div>
        <div className="mt-3">
          Eyemastr’s website may also contain links to other websites or
          resources for convenience. These links do not imply endorsement of the
          linked sites. Eyemastr has no control over the content or operation of
          these sites, and you agree that Eyemastr is not responsible for their
          availability, content, or any damages that may result from using or
          relying on them.
        </div>
      </div>
      <div className="mt-4">
        <h5>
          <b>12. REGISTRATION AND USE</b>
        </h5>
        <div className="mt-3">
          You are responsible for maintaining the confidentiality of all
          registration information, including your login ID, email address, and
          passwords. You are fully accountable for any activity that occurs
          under your account, and you should ensure that you log out after each
          session. Eyemastr will communicate with you through the email address
          provided during registration. If there are any changes to your email,
          it is your responsibility to update it on the Eyemastr platform.
        </div>
        <div className="mt-3">
          Eyemastr takes all reasonable measures to ensure the security of your
          information. If you become aware of any unauthorised use of your
          account, you must contact Eyemastr immediately at India registered
          office address .
        </div>
        <ul className="mt-3" style={{ paddingLeft: "30px" }}>
          <li>
            <b>Address</b> - India address
          </li>
          <li>
            <b>Email:</b>{" "}
            <a href="support@eyemastr.com" style={{ color: "blue" }}>
              support@eyemastr.com
            </a>
          </li>
          <li>
            <b>Phone:</b> +91 9654587757
          </li>
        </ul>
        <div className="mt-3">
          Eyemastr will not be liable for any loss or damage resulting from your
          failure to comply with this section.
        </div>
      </div>
      <div className="mt-4">
        <h5>
          <b>13. LEGAL USES</b>
        </h5>
        <div className="mt-3">
          You agree and understand that Your right to access and use the
          Services offered on this Website is personal to You and is not
          transferable by You to any other person or entity. You understand that
          You are authorised to access and use the Services only for legal and
          lawful purposes. You further undertake and state that by using the
          Services, You are in no way impersonating or misrepresenting any
          person or entity. All Services availed are for Yourself only. If You
          are representing individuals, companies, third parties, or any
          entities, You undertake and state that You are authorised to represent
          such entities. You are solely responsible for the consequences arising
          out of such acts, and the Company shall not be held liable in any way.
          Any changes in Your registration information must be promptly updated
          by You. Your access and use of this Website may be interrupted from
          time to time for reasons including, without limitation, malfunction of
          equipment, periodic updating, maintenance, or other actions the
          Website may take at its discretion. While Eyemastr strives to provide
          uninterrupted Services, it does not warrant that the Website will be
          error-free or that any defects will be corrected. Access is at Your
          own risk, and Eyemastr shall not be liable for any damages related to
          Your use or inability to access the Website.
        </div>
      </div>
      <div className="mt-4">
        <h5>
          <b>14. STORAGE, DELETION, OR TRANSPORT OF DATA</b>
        </h5>
        <div className="mt-3">
          The data provided by You belongs solely and exclusively to You. You
          are permitted to remove or delete Your data, in full or in part, at
          any time. Eyemastr requests that You notify the Company of such
          removal or deletion. If You request the Company to remove or delete
          any portion of Your data, You must provide a written request. Upon
          receipt, Eyemastr will perform the necessary actions and notify You
          accordingly. Once deleted, the Company will not retain any copies of
          such data on its server, and it will be irretrievable. However,
          certain portions of Your data may remain in backups or transaction
          logs solely for backup or service continuity in the event of a
          malfunction.
        </div>
      </div>
      <div className="mt-4">
        <h5>
          <b>15. COMMUNICATION</b>
        </h5>
        <div className="mt-3">
          Eyemastr may send You communications, notices, or alerts via email,
          SMS, phone calls, or other means, which are transactional,
          promotional, or informational in nature. By using the Services, You
          consent to receiving these communications. Should You suppress or
          disable these alerts, Eyemastr recommends reactivating them. Email
          changes will apply to all future communications. Any email sent by
          Eyemastr or its affiliates is intended solely for the addressee and
          may contain privileged information. If You are not the intended
          recipient, please delete the message and notify Eyemastr immediately.
          Eyemastr is not liable for errors or omissions in messages transmitted
          via the internet.
        </div>
      </div>
      <div className="mt-4">
        <h5>
          <b>16. RIGHTS GRANTED BY YOU</b>
        </h5>
        <div className="mt-3">
          In providing the Services, You grant Eyemastr the right to use
          information such as usernames, passwords, personal data, and feedback
          to facilitate Your experience. This information will solely be used to
          provide the Services, and You warrant that You are authorised to
          provide such information. Eyemastr is authorised to access third-party
          accounts on Your behalf as Your agent. By using certain Services, You
          authorise the Company to submit Your information to third-party sites
          to log You into their systems. You also grant Eyemastr a licence to
          use Your suggestions and feedback for marketing, promotional, or
          advertising purposes without any obligation to compensate You.
        </div>
      </div>
      <div className="mt-4">
        <h5>
          <b>17. YOUR POSTINGS ON WEBSITE</b>
        </h5>
        <div className="mt-3">
          Eyemastr encourages You to post content on public forums, blogs, or
          other areas of the Website. You are solely responsible for the content
          posted and warrant that You have the necessary rights to post such
          content. By posting content, You grant Eyemastr a worldwide,
          royalty-free licence to use, reproduce, distribute, display, modify,
          and promote such content. Eyemastr does not control the use of
          personally identifiable information posted in public forums and is not
          responsible for any misuse of such information.
        </div>
      </div>
      <div className="mt-4">
        <h5>
          <b>18. INTELLECTUAL PROPERTY</b>
        </h5>
        <div className="mt-3">
          This Website and any software used in connection with it contain
          proprietary information protected by intellectual property laws. All
          content, including the "look and feel" of the Website, is owned or
          licensed by Eyemastr and is protected by copyright and other laws.
          Except as expressly authorised, You agree not to modify, sell,
          distribute, or create derivative works based on this Website or the
          Services offered. Eyemastr owns or licences all trademarks, service
          marks, and logos displayed on the Website. You may download or print
          content from the Website for personal, non-commercial use only. Any
          other use is prohibited without prior written consent.
        </div>
      </div>
      <div className="mt-4">
        <h5>
          <b>19. PROHIBITED ACTIVITIES</b>
        </h5>
        <div className="mt-3">You agree not to:</div>
        <ul className="mt-3" style={{ paddingLeft: "30px" }}>
          <li>
            Upload or transmit harmful, obscene, or objectionable content.
          </li>
          <li>Impersonate others or misrepresent information.</li>
          <li>
            Disrupt the operation of the Website or its associated servers.
          </li>
          <li>Violate any applicable laws.</li>
          <li>Post unsolicited advertising or spam.</li>
          <li>Attempt to reverse-engineer the Website's software.</li>
          <li>Interfere with other Users' use of the Services.</li>
        </ul>
        <ul className="mt-3" style={{ paddingLeft: "30px" }}>
          <li>
            <b>Address</b> - India address
          </li>
          <li>
            <b>Email:</b>
            <a href="support@eyemastr.com" style={{ color: "blue" }}>
              support@eyemastr.com
            </a>
          </li>
          <li>
            <b>Phone:</b> +91 9654587757
          </li>
        </ul>
      </div>
      <div className="mt-4">
        <h5>
          <b>20. DISCLAIMER</b>
        </h5>
        <div className="mt-3">
          All content and services provided through this website are offered on
          an "as-is" and "as-available" basis. Eyemastr makes no warranties,
          express or implied, regarding the accuracy, reliability, or
          completeness of the content or services. Your use of the service and
          purchase of products are at your own risk. We employ best practices to
          safeguard against viruses but do not guarantee that our website is
          free from harmful components.
        </div>
      </div>
      <div className="mt-4">
        <h5>
          <b>21. LIMITATION OF LIABILITY</b>
        </h5>
        <div className="mt-3">
          Eyemastr is not liable for any indirect, incidental, or consequential
          damages arising from your use of our website, services, or products.
          This includes, but is not limited to, loss of business, profits, or
          data.
        </div>
      </div>
      <div className="mt-4">
        <h5>
          <b>22. INDEMNITY BY YOU</b>
        </h5>
        <div className="mt-3">
          You agree to indemnify and hold Eyemastr, its officers, directors, and
          employees harmless from any claims, damages, or expenses arising from
          your breach of this Agreement, misuse of the services, or negligent
          actions.
        </div>
        <div className="mt-3">For further inquiries, visit:</div>
        <ul className="mt-3" style={{ paddingLeft: "30px" }}>
          <li>
            <b>Address</b> - India address
          </li>
          <li>
            <b>Email:</b>
            <a href="support@eyemastr.com" style={{ color: "blue" }}>
              support@eyemastr.com
            </a>
          </li>
          <li>
            <b>Phone:</b> +91 9654587757
          </li>
        </ul>
      </div>
      <div className="mt-4">
        <h5>
          <b>23. TERMINATION</b>
        </h5>
        <div className="mt-3">
          This Agreement will be applicable and binding on both parties (You and
          the Company), unless terminated as described below:
        </div>
        <ul className="mt-4" style={{ paddingLeft: "30px" }}>
          <li>
            (a) By You, by providing written notice at least 3 (three) business
            days in advance.
          </li>
          <li>
            (b) Closure of Your account by the Company or You for any reason,
            effective immediately.
          </li>
          <li>
            (c) The Company may terminate this Agreement and close Your account
            if it becomes aware that You have breached any of the terms and
            conditions, either intentionally or by implication.
          </li>
          <li>
            (d) The Company may terminate the Agreement if required to do so by
            law.
          </li>
        </ul>
        <div className="mt-3">
          For all communications related to termination or other inquiries,
          please use the following India registered office address
        </div>
        <div className="mt-3">
          <b>Address</b>- India address
        </div>
        <div className="mt-3">
          <b>Email:</b>
          <a href="support@eyemastr.com" style={{ color: "blue" }}>
            support@eyemastr.com
          </a>
        </div>
        <div className="mt-3">
          <b>Phone:</b> +91 9654587757
        </div>
        <div className="mt-3">
          The Company expressly states that this Service (including but not
          limited to its underlying network, system, software, servers, various
          directories, message boards, blogs, tools, information, and databases)
          is intended solely for End Users who are legally permitted to enter
          into a contract. This Service is not intended for minors or
          individuals who are not permitted to enter a valid and binding
          contract. If the Company learns, from authentic and valid resources,
          that a particular End User does not meet these criteria, the Company
          will immediately close that user’s account and delete all related
          information without any obligation or liability towards the said End
          User.
        </div>
      </div>
      <div className="mt-4">
        <h5>
          <b>24. JURISDICTION</b>
        </h5>
        <div className="mt-3">
          The Company controls and operates this Website from its registered
          office in [India]. We make no representation that these materials are
          appropriate or available for use in other locations. If You use this
          Website from other locations, You are responsible for compliance with
          applicable local laws. This Agreement shall be treated as if it were
          executed and performed , India, and shall be governed by and construed
          in accordance with local domestic laws of India, without regard to
          conflict of law principles.
        </div>
        <div className="mt-3">
          All legal proceedings arising out of or in connection with this
          Agreement shall be brought solely India. All disputes will be resolved
          according to the rules specified under the Indian Arbitration and
          Conciliation Act, will have exclusive jurisdiction regarding the
          subject matter of this Agreement.
        </div>
      </div>
      <div className="mt-4">
        <h5>
          <b>25. MISCELLANEOUS</b>
        </h5>
        <ul className="mt-3" style={{ paddingLeft: "30px" }}>
          <li>
            (a) The language in this Agreement will be interpreted fairly and
            not strictly for or against either party.
          </li>
          <li>
            (b) Should any part of this Agreement be held invalid or
            unenforceable, that portion will be construed consistent with
            applicable law, and the remaining parts will remain in full force
            and effect without impairment or invalidation.
          </li>
          <li>
            (c) To the extent that anything in or associated with the Website
            conflicts with this Agreement, this Agreement shall take precedence.
          </li>
          <li>
            (d) Failure of the Company to enforce any provision of this
            Agreement shall not be deemed a waiver of such provision nor of the
            right to enforce it.
          </li>
          <li>
            (e) This Agreement may only be amended by the same electronic means
            used to enter into it or by a written document specifically
            referring to this Agreement, executed by both parties.
          </li>
          <li>
            (f) Eyemastr Solutions Pvt. Ltd. may sell products under various
            brands and sub-brands, including its own brands For any issues, you
            may contact our grievance officer at [support@eyemastr.com] or call
            [+91 9654587757].
          </li>
        </ul>
      </div>
      <div className="mt-4">
        <h5>
          <b>26. TERMS AND CONDITIONS FOR EYE TESTING APPOINTMENT</b>
        </h5>
        <div className="mt-3">
          Eyemastr offers an eye-testing appointment booking service. You may
          make appointments by visiting [http://www.eyemastr.com] or calling
          [+91 9654587757]. By making an appointment, You agree to the
          following:
        </div>
        <ul className="mt-3" style={{ paddingLeft: "30px" }}>
          <li>
            (i) You consent to an executive visiting the designated premises for
            the purpose of eye testing and agree to pay any charges associated
            with the eye testing.
          </li>
          <li>
            (ii) You will be available at the designated premises at the chosen
            date and time.
          </li>
          <li>
            (iii) The information You provide during the appointment process,
            along with feedback provided to the executive, is true and accurate.
          </li>
          <li>
            (iv) You consent to the Company contacting You in connection with
            the appointment.
          </li>
        </ul>
        <div className="mt-3">
          Calls made for booking appointments may be recorded for quality and
          feedback purposes.
        </div>
        <div className="mt-3">
          The executive will not undertake eye examination or recommend further
          action in the following cases:
        </div>
        <ul className="mt-3" style={{ paddingLeft: "30px" }}>
          <li>
            (i) If 6/6 vision (best-corrected visual acuity) cannot be achieved
            in any eye.
          </li>
          <li>
            (ii) If any abnormalities in the cornea or external eye structures,
            like scars or excessive redness, are detected.
          </li>
          <li>(iii) If blood sugar or hypertension is not controlled.</li>
          <li>(iv) If vision is not improving with the pinhole test.</li>
          <li>
            (v) If a valid reading cannot be obtained with a spot vision device,
            and no previous prescription is available.
          </li>
          <li>(vi) If the patient is below the age of 12.</li>
          <li>(vii) If the patient has glaucoma.</li>
        </ul>
        <div className="mt-3">
          Please note that the refraction and prescription provided are not a
          complete eye check-up. This elementary check includes:
        </div>
        <ul className="mt-3" style={{ paddingLeft: "30px" }}>
          <li>(i) External eye examination.</li>
          <li>(ii) Eye mobility assessment.</li>
          <li>(iii) Near-point convergence assessment.</li>
          <li>(iv) Vision assessment.</li>
          <li>(v) Refractive error assessment.</li>
          <li>(vi) Providing prescriptions for spectacle lenses and frames.</li>
        </ul>
        <div className="mt-3">
          You may provide preferred time slots while making the appointment, but
          the Company does not guarantee that the executive will arrive on time.
          If delayed, the executive will try to contact You in advance. In case
          of unavoidable circumstances, the appointment may need to be
          rescheduled.
        </div>
        <div className="mt-3">
          You authorise the executive to enter the designated premises for the
          purpose of conducting the eye test. If the premises are not
          accessible, an alternate location may be designated. The Company
          reserves the right to cancel appointments that are not reasonably
          accessible.
        </div>
        <div className="mt-3">
          You agree to provide a safe working environment, sufficient workspace,
          power supply, and any other required facilities for conducting the eye
          test. All executives are certified optometrists or refractionists. The
          Company will not be liable for any actions of the executive.
        </div>
        <div className="mt-3">
          The results of the eye test are based on the information You provide
          and may vary within acceptable limits.
        </div>
        <div className="mt-3">
          In no event shall the Company be liable for any direct, special,
          indirect, or consequential damages resulting from the eye testing
          service, including but not limited to, lost business, personal injury,
          improper diagnosis, or improper treatment.
        </div>
      </div>
    </div>
  );
}
