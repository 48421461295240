import React from "react";

export default function PrivacyPolicy() {
  return (
    <div className="p-4 card">
      <h3>Eyemastr Privacy policy</h3>
      <div className="mt-4">
        <h6>
          <b>SECURITY AND PRIVACY</b>
        </h6>
        <div className="mt-3">
          Eyemastr, a private limited company duly incorporated under the
          Companies Act, 1956 (hereinafter referred to as ‘Company’), values
          your privacy and is committed to protecting your information. This
          Privacy Policy describes how Eyemastr (referred to as “we,” “our,” or
          “us”) collects, uses, and protects your data when you visit our app or
          website. By using our services, you agree to the collection, use, and
          sharing of your information in accordance with this policy.
        </div>
        <div className="mt-3">
          <b>
            This Privacy Policy is linked on our app’s Google Play listing and
            is accessible within the app itself.
          </b>
        </div>
        <h6 className="mt-3">
          <b>Contact</b>
        </h6>
        <div className="mt-3">
          If you have questions about our Privacy Policy, please contact us at
        </div>
        <div className="mt-3">
          <b>Email:</b>
          <span className="ms-2">support@eyemastr.com</span>
        </div>
        <div className="mt-3">
          <b>Phone:</b>
          <span className="ms-2">+91 9654587757.</span>
        </div>
        <h4 className="mt-3">
          <b>Information We Collect</b>
        </h4>
        <h4 className="mt-3">
          <b>Personal and Sensitive Data</b>
        </h4>
        <div className="mt-3">
          With your consent, we may collect personal information when you visit
          our stores, use our app, or interact with us. This includes, but is
          not limited to:
        </div>
        <ul className="mt-3" style={{ paddingLeft: "30px" }}>
          <li>
            <b>Personal information:</b>
            <span className="ms-2">
              To measure your pupillary distance and face width, and to gather
              other optical information
            </span>
          </li>
          <li>
            <b>Sensitive information:</b>
            <span className="ms-2">
              Photos, scans, and images for features such as 3D Try On, which we
              do not share with third parties
            </span>
          </li>
          <li>
            <b>Location data:</b>
            <span className="ms-2">
              We may access location data to enhance user experience, provide
              location-based services, and improve our product recommendations.
            </span>
          </li>
        </ul>
        <div className="mt-3">
          You may choose not to provide certain information; however, this may
          affect your use of some features. You may review, update, or delete
          your information by contacting us.
        </div>
        <h6 className="mt-3">
          <b>Automatic Information</b>
        </h6>
        <div className="mt-3">
          We collect certain types of information automatically, such as device
          type, operating system, IP address, and usage data. This helps us
          optimize our services and provide a better user experience.
        </div>
        <h4 className="mt-3">
          <b>How We Use Your Information</b>
        </h4>

        <ul className="mt-3" style={{ paddingLeft: "30px" }}>
          <li>
            <b>Order fulfillment:</b>
            <span className="ms-2">
              We use data to process and communicate about orders.
            </span>
          </li>
          <li>
            <b>Personalization:</b>
            <span className="ms-2">
              To provide customized product recommendations.
            </span>
          </li>
          <li>
            <b>Communication:</b>
            <span className="ms-2">
              We use your data to communicate updates, promotions, and other
              relevant information.
            </span>
          </li>
          <li>
            <b>Location-based Services:</b>
            <span className="ms-2">
              Location data is used to customize services and provide relevant
              content based on your location.
            </span>
          </li>
          <li>
            <b>Compliance with Law: </b>
            <span className="ms-2">
              We use your data as needed to comply with applicable laws.
            </span>
          </li>
        </ul>
        <h6 className="mt-3">
          <b>Data Sharing and Disclosure</b>
        </h6>
        <div className="mt-3">
          We may share your data with affiliates, third-party service providers,
          or other entities only as necessary to fulfill our services or comply
          with the law. Any sharing of personal data adheres to our stringent
          security standards.
        </div>

        <div>
          <b>Note:</b>
          <span className="ms-2">
            We do not sell or rent personal information to third parties for
            marketing purposes.
          </span>
        </div>
        <h6 className="mt-3">
          <b>Data Security</b>
        </h6>
        <div className="mt-3">
          We prioritize the protection of your data and employ industry-standard
          measures, such as encryption, to safeguard information during
          transmission. Your data is stored securely on our servers with limited
          access granted only to authorized personnel.
        </div>
        <h6 className="mt-3">
          <b>Data Retention and Deletion Policy</b>
        </h6>
        <div className="mt-3">
          We retain personal data for as long as necessary to provide our
          services or as required by law. Location data and any other sensitive
          data you provide will be securely stored and deleted after 5 years or
          upon your request, as per legal requirements.
        </div>
        <h4 className="mt-3">
          <b>Your Rights and Duties</b>
        </h4>
        <div className="mt-3">Under applicable law, you have rights to:</div>
        <ul className="mt-3" style={{ paddingLeft: "30px" }}>
          <li>
            <b>Access information:</b>
            <span className="ms-2">Obtain a summary of your data.</span>
          </li>
          <li>
            <b>Correction and erasure:</b>
            <span className="ms-2">
              Request corrections or deletion of your personal data.
            </span>
          </li>
          <li>
            <b>Grievance redressal:</b>
            <span className="ms-2">
              Contact us if you have concerns regarding your data.
            </span>
          </li>
          <li>
            <b>Withdraw consent:</b>
            <span className="ms-2">
              Withdraw consent at any time, unless otherwise restricted by law.
            </span>
          </li>
        </ul>
        <div className="mt-3">
          You are expected to provide accurate information and not impersonate
          others while using our services.
        </div>
        <h6 className="mt-3">
          <b>Children and Persons with Disability</b>
        </h6>
        <div className="mt-3">
          Our app does not knowingly collect data from individuals under 18
          without parental or guardian consent.
        </div>

        <h6 className="mt-3">
          <b>Cookie Policy</b>
        </h6>
        <div className="mt-3">
          We use cookies to enhance your browsing experience and to provide
          personalized services. You can manage your cookie settings in your
          browser.
        </div>

        <h6 className="mt-3">
          <b>NDNC Policy</b>
        </h6>
        <div className="mt-3">
          By registering with us, you consent to receive communication regarding
          updates, products, and promotions even if you are registered under
          DND, DNC, or NCPR services.
        </div>
      </div>
    </div>
  );
}
