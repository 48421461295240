import React, { useState } from "react";
import "./ShareYourConcern.css";
import { Link, useNavigate } from "react-router-dom";

export default function ShareYourConcerns() {
  const [selectedConcerns, setSelectedConcerns] = useState([]);
  const navigation = useNavigate();

  const symptoms = [
    "Headache",
    "Blurred vision all time",
    "Tiredness during computer work",
    "Frequent watering in eyes",
    "Feeling dryness in eyes",
    "Frequent redness in eyes",
    "Blurred vision in near work",
    "Blurred vision in distance",
    "Squeezing eyes while watching TV",
    "Difficulty in Sunlight",
  ];

  const handleSymptomClick = (symptom) => {
    if (selectedConcerns.includes(symptom)) {
      setSelectedConcerns(selectedConcerns.filter((item) => item !== symptom));
    } else {
      setSelectedConcerns([...selectedConcerns, symptom]);
    }
  };

  const handleSubmit = () => {
    sessionStorage.setItem(
      "selectedConcerns",
      JSON.stringify(selectedConcerns)
    );
    navigation("/symptoms");
  };

  return (
    <div>
        <div className="ps-4 mt-2">
        <Link to="https://eyemastr.com/" className="link_style">
        <button
          className="btn btn-light"
          
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="pe-3"
            width="24"
            height="24"
            fill="currentColor"
            class="bi bi-arrow-left"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"
            />
          </svg>
         
        </button>
        </Link>
      </div>
      <div className="app-container">
        <p className="description">
          Tell us about your problem area by selecting the matching 3D model and
          choose the tabs correctly to help us accurately assess your symptoms.
        </p>
        <div className="model-display">
          {/* Placeholder for 3D model */}
          <div className="model-placeholder">3D Model</div>
        </div>
        <h3 className="symptom-title">Let us know what you feel</h3>
        <div className="symptom-buttons">
          {symptoms.map((symptom, index) => (
            
            <button
              key={index}
              className={`symptom-button ${
                selectedConcerns.includes(symptom) ? "selected" : ""
              }`}
              onClick={() => {
                handleSymptomClick(symptom);
              }}
              
            >
              {symptom}
            </button>
          ))}
        </div>
        <div className="d-flex justify-content-center">
          <button className="submit-button" onClick={handleSubmit} disabled={selectedConcerns.length === 0}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}
