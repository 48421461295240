import React, { useState, useEffect } from "react";
import "./SlotPicker.css";
import moment from "moment";
import "./Professional.css";
import {
  getDoctorsDetailById,
  getAvailabilityData,
  appAppoinmentByuser,
} from "../../Services/AllApi";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../Components/Loader/Loader";
import toast from "react-hot-toast";
import { Modal, ModalBody } from "reactstrap";
import { IoCloseSharp } from "react-icons/io5";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import axios from "axios";
import { baseUrl } from "../../Services/Endpoint";
import CryptoJS from 'crypto-js';

const stripePromise = loadStripe(
  "pk_live_51Q638VL06v98rXHkK6B7MpLNswfPfqw1iUUNsR53eFaj8ENLR1xUixMYmRtnjZN52FT8Wr0o91CQ2baVu5WA8dv000ZBEE3jzZ"
);

// const stripePromise = loadStripe(
//   "pk_test_51Q638VL06v98rXHkhl5qtB6ItFHwGhu0ryjlRknqn1DvjgdvJeGbpejc6SiEcWPxaRHpmjECKYjKwcBrTuDE2HDg001UcC2ufb"
// );


export default function SlotPicker() {
  const navigation = useNavigate();
  const params = useParams();
  const [getDetailsById, setGetDetailsById] = useState([]);
  const [loader, setLoader] = useState(false);
  const [getSlots, setgetSlots] = useState([]);
  const [selectedDate, setSelectedDate] = useState("Today");
  const [dates, setDates] = useState([]);
  const [selectSlots, setSelectSlots] = useState("");
  const [selectDate, setSelectDate] = useState({
    name: "Today",
    date: new Date(),
  });

  const [openModal, setOpenModal] = useState(false);
  const [clientSecret, setClientSecret] = useState("");

  const  handleSubit =()=>{
    sessionStorage.setItem(
      "selectSlots",
      JSON.stringify(selectSlots)
    );
    navigation("/book-payment");
  }

  const options = {
    clientSecret: clientSecret,
  };

  const getDataById = async () => {
    setLoader(true);
    try {
      let res = await getDoctorsDetailById(params?.id);
      setGetDetailsById(res?.data?.user);
    } catch (err) {
      console.log(err);
    } finally {
      setLoader(false);
    }
  };

  const getAllAvailabilityData = async (date) => {
    const payloadData = {
      professionalId: params.id,
      date: moment(date).format("YYYY/MM/DD"),
    };
    setLoader(true);
    try {
      const res = await getAvailabilityData(payloadData);
      setgetSlots(res.data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoader(false);
    }
  };

  let getData = JSON.parse(sessionStorage.getItem("selectedConcerns"));

  const appointmentBook = async() =>{
    if(JSON?.parse(localStorage?.getItem("UserDetail"))?.token){
      setOpenModal(true);

    }else{
      navigation("/login");
    }
  } 
  const addAppointment = async () => {
    setLoader(true);
    const payload = {
      professionalId: params.id,
      appointmentDateTime: createDateWithTime(selectDate.date, selectSlots),
      serviceType: "offline",
      location: "123 Main St, Suite 400, Cityville, State 12345",
      diagnoses: getData.map((e) => {
        return {
          content: e,
        };
      }),
    };
    console.log("Payload:", payload);
    // return;

    try {
      console.log(0, "console.log zero");

      if (JSON?.parse(localStorage?.getItem("UserDetail"))?.token) {
        console.log(1, "console.log one");
        const response = await appAppoinmentByuser(payload);
        console.log("Appointment booked successfully", response);
        // toast.success("Appointmnet add successfully");
        setOpenModal(true);
      } else {
        navigation("/login");
        console.log(2);
      }
      setLoader(false);
    } catch (err) {
      console.log("Error booking appointment:", err);
    } finally {
      setLoader(false);
    }
  };

  const numberOfDays = 60;

  const generateDates = () => {
    const today = new Date();
    const dates = [];
    for (let i = 0; i < numberOfDays; i++) {
      const date = new Date();
      date.setDate(today.getDate() + i);
      const dayName =
        i === 0
          ? "Today"
          : i === 1
          ? "Tomorrow"
          : date.toLocaleDateString("en-US", {
              weekday: "short",
              day: "numeric",
              month: "short",
            });
      dates.push({ name: dayName, date });
    }
    return dates;
  };

  useEffect(() => {
    getDataById();
    getAllAvailabilityData(new Date());
    setDates(generateDates());
  }, []);

  const handleDateClick = (date) => {
    setSelectedDate(date.name);
    setSelectDate(date);
    getAllAvailabilityData(date.date);
  };

  function createDateWithTime(dateString, timeString) {
    // Parse the date
    const date = new Date(dateString);

    // Parse the time (expected format "hh:mm AM/PM")
    const [time, modifier] = timeString.split(" ");
    let [hours, minutes] = time.split(":").map(Number);
  
    // Adjust hours based on AM/PM
    if (modifier === "PM" && hours !== 12) {
      hours += 12;
    } else if (modifier === "AM" && hours === 12) {
      hours = 0;
    }
  
    // Set the hours and minutes on the date object
    date.setUTCHours(hours, minutes, 0, 0);
  
    // Convert to UTC format
    return date.toISOString();
  }

  useEffect(() => {
    getPaymentIntent();
  }, []);

  async function getPaymentIntent() {
    try {
      let res = await axios.post(`${baseUrl}user/create-payment-intent`, {
        amount: 2500,
      });
      let key = res?.data?.clientSecret;
      setClientSecret(key);
      console.log(key);
    } catch (err) {
      console.log(err);
    }
  }





  return (
    <>
      <Modal isOpen={openModal} size="md" centered>
        <ModalBody>
          <div className="d-flex justify-content-end mb-3">
            <IoCloseSharp
              size={18}
              className="pointer"
              onClick={() => setOpenModal(false)}
            />
          </div>
          {clientSecret && (
            <Elements stripe={stripePromise} options={options}>
              <CheckoutForm createDateWithTime={createDateWithTime} selectDate={selectDate} selectSlots={selectSlots} getData={getData} navigation={navigation}/>
            </Elements>
          )}
        </ModalBody>
      </Modal>
      <div className="ps-4 mt-3">
        <button
          className="btn btn-light"
          onClick={() => navigation("/near-me-eyemaster")}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="currentColor"
            className="bi bi-arrow-left"
            viewBox="0 0 16 16"
          >
            <path d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8" />
          </svg>
        </button>
      </div>
      <div className="container">
        <Loader open={loader} />

        <div className="main_container_book_now">
          <div className="first_div_container_book_now">
            <div className="profile-container">
              <div className="profile-content">
                <div className="profile-picture">
                  <img
                    src={getDetailsById?.profile?.fileUrl}
                    alt="Doctor Avatar"
                  />
                  <div className="doctor-profile-detail">
                    <div className="doctor-name">
                      {getDetailsById?.prefix} {getDetailsById?.firstname}
                    </div>
                    <div className="doctor-details">
                      {getDetailsById?.profession} |{" "}
                      {getDetailsById?.qualification} |{" "}
                      {getDetailsById?.perDayEyeTest} Eye Tests
                    </div>
                    <p>
                      {getDetailsById?.yearsOfExperience} years of experience
                      overall{" "}
                    </p>
                  </div>
                </div>

                <section className="working-time"></section>
                <section className="location mt-5">
                  <h4>Location</h4>
                  <div>
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-geo-alt"
                        viewBox="0 0 16 16"
                      >
                        <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A32 32 0 0 1 8 14.58a32 32 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10" />
                        <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4m0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                      </svg>
                    </span>
                    <span className="ps-2">
                      {getDetailsById?.company}, {getDetailsById?.city},{" "}
                      {getDetailsById?.state}, {getDetailsById?.country}
                    </span>
                  </div>
                </section>
                <section className="mt-4">
                  <span><b>Total Amount</b></span> - {getDetailsById?.country ==="India" ? <span>100 Rs.</span> : <span>25 AED</span>}
                </section>
              </div>
            </div>
          </div>
          <div className="second_div_container_book_now">
            <div className="slot-selector">
              <div className="date-slider">
                {dates.map((date) => (
                  <div
                    key={date.name}
                    className={`tab ${
                      selectedDate === date.name ? "active" : ""
                    }`}
                    onClick={() => handleDateClick(date)}
                  >
                    <p className="tab-title">{date.name}</p>
                  </div>
                ))}
              </div>
              <div className="slot-buttons">
                {getSlots.length > 0 ? (
                  getSlots.map((slot, index) => (
                    <React.Fragment key={index}>
                      {slot.isBooked ? (
                        <button className="slot_disable" disabled>
                          {slot?.startTime}
                        </button>
                      ) : (
                        <button
                          className={`slot ${
                            selectSlots === slot?.startTime ? "selected" : ""
                          }`}
                          onClick={() => {setSelectSlots(slot?.startTime)}}
                        >
                          {selectSlots === slot?.startTime
                            ? slot?.startTime
                            : slot?.startTime}
                        </button>
                      )}
                    </React.Fragment>
                  ))
                ) : (
                  <p className="slot_not_available">
                    No slots are currently available. Please choose another
                    date.
                  </p>
                )}
              </div>
            </div>
            <div className="d-flex justify-content-end mt-3">
             

              {selectSlots && (
                <button
                  className="btn btn-primary"
                  // onClick={addAppointment}

                  // onClick={() => {
                  //   setOpenModal(true);
                  // }}

                  onClick={()=>{appointmentBook()}}
                >
                  Book Appointment
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const CheckoutForm = ({createDateWithTime,selectDate,selectSlots,getData,navigation}) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const id = urlParams.get("id");
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const handleSubmit = async (event) => {
    event.preventDefault();
    const payload = {
      professionalId: params.id,
      appointmentDateTime: createDateWithTime(selectDate.date, selectSlots),
      serviceType: "offline",
      location: "123 Main St, Suite 400, Cityville, State 12345",
      diagnoses: getData.map((e) => {
        return {
          content: e,
        };
      }),
    };
    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      return;
    }
    setLoading(true);

    
const data = [payload];
console.log(data)

// Encrypt
const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), 'secret key 123').toString();

// Decrypt
// const bytes = CryptoJS.AES.decrypt(ciphertext, 'secret key 123');
// const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

console.log(ciphertext,"encrypted data")
// return
    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `https://professional.eyemastr.com/appointment-success?id=${params.id}?encrypt=${ciphertext}`,
        // return_url: `http://localhost:3000/appointment-success?id=${params.id}&encrypt=${encodeURIComponent(ciphertext)}`
        
      },
    });

     // The payment is successful
     if (JSON?.parse(localStorage?.getItem("UserDetail"))?.token) {
      console.log(1, "console.log one");
      const response = await appAppoinmentByuser(payload);
      console.log("Appointment booked successfully", response);
      // toast.success("Appointmnet add successfully");
    } else {
      navigation("/login");
      console.log(2);
    }

    if (result.error) {
      // Show error to your customer
      console.log(result.error.message);
    } else {
     
      console.log("Payment successful!");
    }
  };

  const paymentElementOptions = {
    defaultValues: {
      billingDetails: {
        address: {
          country: "AE", // ISO code for UAE
        },
      },
    },
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement options={paymentElementOptions} />
      <button
        className="btn btn-primary mt-3 w-100"
        disabled={!stripe || loading}
      >
        Submit
      </button>
    </form>
  );
};
