import React, { useState } from 'react'
import style from './style.module.css'
import { BsThreeDotsVertical } from "react-icons/bs";
import DropDownMenu from '../DropDownMenu/DropDownMenu';
import ViewFile from './ViewFile';

export default function DocumentCard({file}) {
    const [viewData, setViewData] = useState({})
    const [openView, setOpenView] = useState(false)

    function cardClick(fileData){
        setOpenView(true)
        setViewData(fileData)
    }
    
    return (
        <>
         <ViewFile
                data={viewData}
                open={openView}
                setOpen={setOpenView}
                setViewData={setViewData}
            />
            <div className={`${style.DCard}`}>
                <div className={`w-100 pointer ${style.dUpper}`} onClick={() => cardClick(file)}>
                    <img src='/images/doc.png' className='w-100 h-100' alt='' />
                </div>
                <div className={`${style.dbottom}`}>
                    <div className='d-flex align-items-center pointer' onClick={() => cardClick(file)}>
                        <img src={`/images/doc.png`} className={`${style.filetype}`} alt='' />
                        <div>
                            <div className='text-black text-capitalize fw-600  f-14' title={file?.fileName}>{file?.fileName?.slice(0, 8)}{file?.fileName?.length > 8 ? '...' : ''}</div>
                            {/* <span className={`${checkStatus(file?.status)} text-capitalize`}>{file?.status}</span> */}
                        </div>
                    </div>
                    {/* {getPermissionAccess("Business", selector, false, 'Edit Business') ?
                        <DropDownMenu button={<BsThreeDotsVertical className='pointer' />}>
                            <MenuItem onClick={() => {
                                setUpdateStatus(file?.status)
                                setDocId(file?.id)
                            }}>Update status</MenuItem>
                            <MenuItem onClick={() => {
                                setDeleteModal(true)
                                setViewData(file)
                            }}>Delete</MenuItem>
                        </DropDownMenu> : ''} */}

                </div>
            </div>
        </>
    )
}
