import React, { useEffect, useState } from "react";
import "./NearMeEyemaster.css";
import { getAllDoctorsList } from "../../Services/AllApi";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../../Components/Loader/Loader";

export default function NearMeEyemaster2() {
  const navigation = useNavigate();
  const [getAlldoctors, setGetAllDoctors] = useState([]);
  const [filteredProfessionals, setFilteredProfessionals] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [userCity, setUserCity] = useState("");
  const [loader, setLoader] = useState(false);

  const userCurrentPosition = { latitude: 40.7128, longitude: -74.006 }; // Default position (New York)

  const calculateDistance = ({ lat1, lon1, lat2, lon2 }) => {
    const R = 6371; // Radius of the Earth in km
    const dLat = (lat2 - lat1) * (Math.PI / 180);
    const dLon = (lon2 - lon1) * (Math.PI / 180);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * (Math.PI / 180)) *
        Math.cos(lat2 * (Math.PI / 180)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // Distance in km
  };

  const getAllData = async () => {
    setLoader(true)
    try {
      let res = await getAllDoctorsList();
      const filteredByDistance = checkInRange(res.data);
      setGetAllDoctors(filteredByDistance);
      setFilteredProfessionals(filteredByDistance);
      console.log(filteredByDistance);
      setLoader(false)
    } catch (err) {
      console.log(err);
      setLoader(false)
    }
  };

  const checkInRange = (doctors) => {
    return doctors.filter((doctor) => {
      if (doctor.lat_long && doctor.lat_long.lat && doctor.lat_long.long) {
        const distance = calculateDistance({
          lat1: userCurrentPosition.latitude,
          lon1: userCurrentPosition.longitude,
          lat2: parseFloat(doctor.lat_long.lat),
          lon2: parseFloat(doctor.lat_long.long),
        });
        console.log(distance);
        return distance < 15; // Filter doctors within 15 km
      }
      return false;
    });
  };

  const handleSearch = (text) => {
    const filtered = getAlldoctors.filter(
      (doctor) =>
        doctor.firstname
          .trim()
          .replaceAll(" ", "")
          .toLowerCase()
          .startsWith(text.trim().toLowerCase()) || text.trim() === ""
    );
    setFilteredProfessionals(filtered);
    setSearchText(text);
  };

  const getCityFromCoordinates = async (lat, lon) => {
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lon}`
      );
      const data = await response.json();
      const city =
        data.address?.city || data.address?.town || data.address?.village;
      setUserCity(city);
    } catch (error) {
      console.error("Error fetching city data:", error);
    }
  };

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          userCurrentPosition.latitude = latitude;
          userCurrentPosition.longitude = longitude;
          getCityFromCoordinates(latitude, longitude);
        },
        (error) => {
          console.error("Error getting user location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }

    getAllData();
  }, []);

  return (
    <>
                  <Loader open={loader} />

      <div className="ps-4 mt-3">
        <Link to="/symptoms"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="pe-3"
            width="24"
            height="24"
            fill="currentColor"
            class="bi bi-arrow-left"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"
            />
          </svg>
        </Link>
      </div>
      <div className="container mb-4">
        <div className="main_conatiner_near_eyemaster">
          <h2>Near My Professionals</h2>
          {/* <p>Showing results near: {userCity || "Fetching location..."}</p> */}
          {/* Uncomment to enable search functionality */}
          {/* <input
          type="text"
          placeholder="Search by name"
          value={searchText}
          onChange={(e) => handleSearch(e.target.value)}
          className="search-input"
        /> */}
        <div className="professionals-list">
          {filteredProfessionals.length > 0 ? (
            <>
              {filteredProfessionals.map((e) => (
                <div
                  className="professional-card pointer"
                  
                  key={e.id}
                >
                  <div className="professional_card_left">
                  <img
                    src={e?.profile?.fileUrl}
                    alt={e.name}
                    className="profile-image"
                  />
                  <div className="professional-info">
                    <h3>
                      {e?.prefix}
                      {e?.firstname}
                    </h3>
                    <p>
                      {e.profession} | {e.qualification}
                    </p>
                  <p>{e?.yearsOfExperience} years of experience overall </p>
                  <p>{e?.city},{e?.company}</p>
                  </div>
                  </div>
                  <div className="view_profile_button" onClick={() => {
                    navigation(`/professional-detail/${e._id}`);
                  }}>
                    View Profile
                  </div>
                </div>
              ))}
              </>
          ):(
            <>
              <p>Currently, no professionals are available nearby. Please try again later.</p>
            </>
          )}
        
        </div>
        </div>

      </div>
    </>
  );
}
