import React from 'react'
import "./Home.css"

export default function HomePage() {


    return (
      <>
        <div style={{ overflowX: "hidden" }}>
          <section className="mt-3">
            <div
              id="carouselExampleIndicators"
              class="carousel slide"
              data-bs-ride="carousel"
            >
              <div class="carousel-indicators">
                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide-to="0"
                  class="active"
                  aria-current="true"
                  aria-label="Slide 1"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide-to="1"
                  aria-label="Slide 2"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide-to="2"
                  aria-label="Slide 3"
                ></button>
              </div>
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <img
                    src="/Img/banner1.png"
                    className="d-block w-100"
                    alt="..."
                  />
                </div>
                <div class="carousel-item">
                  <img
                    src="/Img/banner2.png"
                    className="d-block w-100"
                    alt="..."
                  />
                </div>
                <div class="carousel-item">
                  <img
                    src="/Img/banner3.png"
                    className="d-block w-100"
                    alt="..."
                  />
                </div>
              </div>
              <button
                class="carousel-control-prev"
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide="prev"
              >
                <span
                  class="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span class="visually-hidden">Previous</span>
              </button>
              <button
                class="carousel-control-next"
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide="next"
              >
                <span
                  class="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span class="visually-hidden">Next</span>
              </button>
            </div>
          </section>

          <div className="topHeaderContainer">
            <div className="slideritem1">
              <div className="sliderimg1">
                <img src="/images/1.jpg" className="w-100 h-100" alt="" />
              </div>
              <div className="text-center">
                <div className="slidername1">Eyeglasses</div>
              </div>
            </div>
            <div className="slideritem1">
              <div className="sliderimg1">
                <img src="/images/2.jpg" className="w-100 h-100" alt="" />
              </div>
              <div className="text-center">
                <div className="slidername1">Sunglasses</div>
              </div>
            </div>
            <div className="slideritem1">
              <div className="sliderimg1">
                <img src="/images/lenc1.jpg" className="w-100 h-100" alt="" />
              </div>
              <div className="text-center">
                <div className="slidername1">Contact Lenses</div>
              </div>
            </div>
            <div className="slideritem1">
              <div className="sliderimg1">
                <img src="/images/3.jpg" className="w-100 h-100" alt="" />
              </div>
              <div className="text-center">
                <div className="slidername1">Screen Glasses</div>
              </div>
            </div>
            <div className="slideritem1">
              <div className="sliderimg1">
                <img src="/images/power1.jpg" className="w-100 h-100" alt="" />
              </div>
              <div className="text-center">
                <div className="slidername1">Power Sunglasses</div>
              </div>
            </div>
            <div className="slideritem1">
              <div className="sliderimg1">
                <img src="/images/prog1.jpg" className="w-100 h-100" alt="" />
              </div>
              <div className="text-center">
                <div className="slidername1">Progressive Lenses</div>
              </div>
            </div>
          </div>

          {/* <section>
                <div className="item_card_container">
                    <div className="item-card">
                        <img src="/Img/men_accessories.png.png" alt="Eyeglasses" />
                        <p>Eyeglasses</p>
                    </div>
                    <div className="item-card">
                        <img src="/Img/image (1).png" alt="Eyeglasses" />
                        <p>Sunglasses</p>
                    </div>
                    <div className="item-card">
                        <img src="/Img/image (2).png" alt="Eyeglasses" />
                        <p>Computer Glasses</p>
                    </div>
                    <div className="item-card">
                        <img src="/Img/image (3).png" alt="Eyeglasses" />
                        <p>Reading Glasses</p>
                    </div>
                    <div className="item-card">
                        <img src="/Img/image (4).png" alt="Eyeglasses" />
                        <p>Contact Lenses</p>
                    </div>
                    <div className="item-card">
                        <img src="/Img/Img_margin.png" alt="Eyeglasses" />
                        <p>Progressive</p>
                    </div>
                    <div className="item-card">
                        <img src="/Img/image (5).png" alt="Eyeglasses" />
                        <p>kids</p>
                    </div>
                </div>

            </section> */}
          {/* <!-- glass section --> */}

          {/* <!-- gif 1 --> */}

          <section className="mt-3">
            <div className="containe_gif_one ">
              <img src="/Img/Container.gif" style={{ width: "100%" }} alt="" />
            </div>
          </section>

          {/* <!-- gif 1 --> */}

          {/* <!-- How It work --> */}
          <section className="mt-3">
            <div>
              <img
                src="/Img/7 How It Works.png"
                style={{ width: "100%" }}
                alt=""
              />
            </div>
          </section>

          {/* <!-- How It work --> */}

          {/* <!-- greatest deal --> */}

          <section className="mt-3">
            <div className="containe_gif_one ">
              <img
                src="/Img/2_790-_banner_1920_x_570_px.gif"
                style={{ width: "100%" }}
                alt=""
              />
            </div>
          </section>

          {/* <!-- greatest deal --> */}

          {/* <!-- Choose your favorite style --> */}

          <section className="mt-3">
            <div className="choosText px-2">Choose Your Favorite Style</div>
            <div className="topHeaderContainer2">
              <div className="slideritem1">
                <div className="sliderimg1 bg-white">
                  <img src="/Img/rectangle.jpg.png" className="w-100" alt="" />
                </div>
                <div className="text-center">
                  <div className="slidername1">Rectangle</div>
                </div>
              </div>
              <div className="slideritem1">
                <div className="sliderimg1 bg-white">
                  <img src="/Img/round1.jpg.png" className="w-100" alt="" />
                </div>
                <div className="text-center">
                  <div className="slidername1">Round</div>
                </div>
              </div>
              <div className="slideritem1">
                <div className="sliderimg1 bg-white">
                  <img src="/Img/way1.jpg.png" className="w-100" alt="" />
                </div>
                <div className="text-center">
                  <div className="slidername1">Wayfarer</div>
                </div>
              </div>
              <div className="slideritem1">
                <div className="sliderimg1 bg-white">
                  <img src="/Img/cateye1.jpg.png" className="w-100" alt="" />
                </div>
                <div className="text-center">
                  <div className="slidername1">Cat-Eye</div>
                </div>
              </div>
              <div className="slideritem1">
                <div className="sliderimg1 bg-white">
                  <img src="/Img/hexa2.jpg.png" className="w-100" alt="" />
                </div>
                <div className="text-center">
                  <div className="slidername1">Hexagonal</div>
                </div>
              </div>
              <div className="slideritem1">
                <div className="sliderimg1 bg-white">
                  <img src="/Img/aviator.png" className="w-100" alt="" />
                </div>
                <div className="text-center">
                  <div className="slidername1">Aviator</div>
                </div>
              </div>
            </div>
          </section>

          {/* <!-- best seller --> */}
          <section className="mt-3 px-2">
            <div className="trending_container px-2">
              <h1 className="Trending_title">Review</h1>
              <button className="view-all">View All</button>
            </div>

            <div className="best_seller_product_card">
              <div className="best_seller_card_header">
                <span className="best_sellers_tilte">Best Sellers</span>
                <span className="best_seller_colors">3 Colors</span>
              </div>
              <div className="best_seller_product_image">
                <img src="/Img/skyBlue.png" alt="AP2548 Optics" />
              </div>
              <div className="best_seller_bottom">
                <div className="best_seller_product_info">
                  <h2 className="best_seller_product_title">AP2548 Optics</h2>
                  <p className="best_seller_brand_name">Alex Perry</p>
                  <p className="best_seller_price">₹ 2999</p>
                </div>
                <div className="best_seller_zoom_icon">
                  <img src="/Img/frame.png" alt="Zoom Icon" />
                </div>
              </div>
            </div>
          </section>

          {/* <!-- best seller --> */}

          {/* <!-- Choose your favorite style --> */}

          {/* <!-- gif 3 --> */}
          <section className="mt-3">
            <div className="containe_gif_two ">
              <img
                src="/Img/thirdgif.gif"
                style={{ width: "100%", height: "100%" }}
                alt=""
              />
            </div>
          </section>

          {/* <!-- gif 3 --> */}

          {/* <!-- trending collection --> */}

          <section className="mt-3 px-2">
            <div className="d-flex justify-content-between align-items-center">
              <h1 className="Trending_title">Trending Collections</h1>
              <button className="view-all">View All</button>
            </div>
            <div className="row mt-3">
              <div className="col-md-3 col-sm-3 col-3">
                <div className="trending_card w-100">
                  <img
                    src="/Img/Link.png"
                    alt="Titan Signature"
                    className="card-image"
                  />
                </div>
              </div>
              <div className="col-md-3 col-sm-3 col-3">
                <div className="trending_card w-100">
                  <img
                    src="/Img/Container.png"
                    alt="High On Fashion"
                    className="card-image"
                  />
                </div>
              </div>
              <div className="col-md-3 col-sm-3 col-3">
                <div className="trending_card w-100">
                  <img
                    src="/Img/Container (1).png"
                    alt="Shades of Spring"
                    className="card-image"
                  />
                </div>
              </div>
              <div className="col-md-3 col-sm-3 col-3">
                <div className="trending_card w-100">
                  <img
                    src="/Img/Container (2).png"
                    alt="Elements"
                    className="card-image"
                  />
                </div>
              </div>
            </div>
          </section>
          {/* <!-- trending collection --> */}

          {/* <!-- unreviel section --> */}

          <section className="mt-3 px-2">
            <div className="d-flex">
              <h1 className="Trending_title">Unrivalled Excellence</h1>
            </div>

            <div className="row">
              <div className="col-md-7 col-sm-7 col-7">
                <img
                  src="/Img/frame_container1.png"
                  alt="Sunglasses"
                  style={{ width: "100%" }}
                />
              </div>

              <div className="col-md-5 col-sm-5 col-5">
                <img
                  src="/Img/frame_container.png"
                  alt="Hand holding glasses"
                  style={{ width: "100%", height: "100%" }}
                />
              </div>
            </div>
          </section>

          <section className="mt-3 px-2">
            <div className="">
              <img src="/Img/our_lences.png" style={{ width: "100%" }} alt="" />
            </div>
          </section>

          <section className="mt-3 px-2">
            <div className="row">
              <div className="col-5">
                <img
                  src="/Img/try_it.png"
                  alt="Hand holding glasses"
                  style={{ width: "100%", height: "100%" }}
                />
              </div>
              <div className="col-7">
                <img
                  src="/Img/need_frame.png"
                  alt="Sunglasses"
                  style={{ width: "100%" }}
                />
              </div>
            </div>
          </section>

          <section className="mt-3 px-2">
            <div>
              <img src="/Img/group12.png" style={{ width: "100%" }} alt="" />
            </div>
          </section>
          {/* <!-- unreviel section --> */}

          {/* <!-- explore by brand --> */}
          <section className="mt-3 px-2">
            <div className="d-flex justify-content-center">
              <h1 className="Trending_title ">Explore By Brands</h1>
            </div>
            <div className="d-flex justify-content-center">
              <p>Wear your favorite brand with the trust Eyemastr</p>
            </div>
            <div className="sub_container_choose">
              <div className="carousel-item active">
                <div className="d-flex">
                  <div className="chooseCard">
                    <img
                      src="/Img/explore1.png"
                      className="d-block w-100"
                      alt="Fastrack"
                    />
                  </div>
                  <div className="chooseCard">
                    <img
                      src="/Img/explore2.png"
                      className="d-block w-100"
                      alt="Tommy Hilfiger"
                    />
                  </div>
                  <div className="chooseCard">
                    <img
                      src="/Img/explore3.png"
                      className="d-block w-100"
                      alt="Vogue Eyewear"
                    />
                  </div>
                  <div className="chooseCard">
                    <img
                      src="/Img/explore4.png"
                      className="d-block w-100"
                      alt="Titan Eyewear"
                    />
                  </div>
                  <div className="chooseCard">
                    <img
                      src="/Img/explore1.png"
                      className="d-block w-100"
                      alt="Fastrack"
                    />
                  </div>
                  <div className="chooseCard">
                    <img
                      src="/Img/explore2.png"
                      className="d-block w-100"
                      alt="Tommy Hilfiger"
                    />
                  </div>
                  <div className="chooseCard">
                    <img
                      src="/Img/explore3.png"
                      className="d-block w-100"
                      alt="Vogue Eyewear"
                    />
                  </div>
                  <div className="chooseCard">
                    <img
                      src="/Img/explore4.png"
                      className="d-block w-100"
                      alt="Titan Eyewear"
                    />
                  </div>
                </div>
              </div>
              <div className="carousel-item">
                <div className="row"></div>
              </div>
            </div>
          </section>

          {/* <!-- explore by brand --> */}

          {/* <!-- choosen na eye --> */}

          <section className="mt-3 px-2">
            <h1 className="Trending_title ">
              Choose an Eyewear of your Choice
            </h1>
            <p className="mt-3">
              Delivering premium quality at affordable rates!
            </p>
            <div className="row">
              <div className="col-3">
                <img src="/Img/wear1.png" alt="" style={{ width: "90%" }} />
              </div>
              <div className="col-3">
                <img src="/Img/wear2.png" alt="" style={{ width: "90%" }} />
              </div>
              <div className="col-3">
                <img src="/Img/wear3.png" alt="" style={{ width: "90%" }} />
              </div>
              <div className="col-3">
                <img src="/Img/wear4.png" alt="" style={{ width: "90%" }} />
              </div>
            </div>
          </section>
          {/* <!-- choosen na eye --> */}

          {/* <!-- review section --> */}

          <section className="mt-3 px-2">
            <div className="d-flex justify-content-between align-items-center">
              <h1 className="Trending_title">Review</h1>
              <button className="view-all">View All</button>
            </div>
            <div className="row">
              <div className="col-md-4 col-sm-4 col-6">
                <div className="review_card">
                  <div className="review_card_content">
                    <div className="review_user_info">
                      <div className="review_text_info">
                        <h3>Awesome Deals</h3>
                        <p>I highly recommend shopping from EyeMastr</p>
                      </div>
                      <img
                        src="/Img/reviewavatar.png"
                        alt="User Avatar"
                        className="review_avatar"
                      />
                    </div>
                    <div className="review_rating">
                      <span className="review_stars">★★★★★</span>
                      <span className="review_rating_score">5.0</span>
                    </div>
                  </div>
                  <img
                    src="/Img/reviewframe.png"
                    alt="Product Image"
                    className="review_product_image"
                  />
                </div>
              </div>
              <div className="col-md-4 col-sm-4 col-6">
                <div className="review_card">
                  <div className="review_card_content">
                    <div className="review_user_info">
                      <div className="review_text_info">
                        <h3>Awesome Deals</h3>
                        <p>I highly recommend shopping from EyeMastr</p>
                      </div>
                      <img
                        src="/Img/reviewavatar.png"
                        alt="User Avatar"
                        className="review_avatar"
                      />
                    </div>
                    <div className="review_rating">
                      <span className="review_stars">★★★★★</span>
                      <span className="review_rating_score">5.0</span>
                    </div>
                  </div>

                  <img
                    src="/Img/reviewframe.png"
                    alt="Product Image"
                    className="review_product_image"
                  />
                </div>
              </div>
              <div className="col-md-4 col-sm-4 col-6">
                <div className="review_card">
                  <div className="review_card_content">
                    <div className="review_user_info">
                      <div className="review_text_info">
                        <h3>Awesome Deals</h3>
                        <p>I highly recommend shopping from EyeMastr</p>
                      </div>
                      <img
                        src="/Img/reviewavatar.png"
                        alt="User Avatar"
                        className="review_avatar"
                      />
                    </div>
                    <div className="review_rating">
                      <span className="review_stars">★★★★★</span>
                      <span className="review_rating_score">5.0</span>
                    </div>
                  </div>

                  <img
                    src="/Img/reviewframe.png"
                    alt="Product Image"
                    className="review_product_image"
                  />
                </div>
              </div>
            </div>
          </section>
          {/* <!-- review section --> */}

          {/* <!-- Latest News & Resources --> */}
          <section className="mt-3 px-2">
            <div className="d-flex justify-content-center">
              <h1 className="latest_new_title ">Latest News & Resources</h1>
            </div>
            <div className="d-flex justify-content-center">
              <p className="fs-5">
                See the developments that have occurred in the world
              </p>
            </div>
            <div className="row">
              <div className="col-lg-6 col-12 d-sm-block d-none">
                <img src="/Img/latestnewleft.png" className="w-100" alt="" />
              </div>
              <div className="col-lg-6 col-12 ">
                <div className="d-flex flex-lg-column flex-md-row flex-sm-row flex-column gap-3 mt-lg-0 mt-3">
                  <div className="right_latest_upper d-flex">
                    <div className="w-100">
                      <img src="/Img/vlog.png" alt="" className="w-100" />
                    </div>
                    <div>
                      <p className="fs-3">
                        <b>
                          Exploring Trends in Eyewear Industry for the Year 2024
                          - Latest
                        </b>
                      </p>
                      <p className="fs-5">
                        In the ever-evolving realm of Eyewear, 2023 promises to
                        be a year of transformative trends{" "}
                      </p>
                    </div>
                  </div>

                  <div className="right_latest_upper d-flex">
                    <div className="w-100">
                      <img src="/Img/vlog.png" alt="" className="w-100" />
                    </div>
                    <div>
                      <p className="fs-3">
                        <b>
                          Exploring Trends in Eyewear Industry for the Year 2024
                          - Latest
                        </b>
                      </p>
                      <p className="fs-5">
                        In the ever-evolving realm of Eyewear, 2023 promises to
                        be a year of transformative trends{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* <!-- Latest News & Resources --> */}

          {/* <!-- follow us on instagram --> */}
          <section className="my-3 px-2">
            <h5>Follow Us on Instagram</h5>
            <h5 className="eye_master_heading">@Eyemastr</h5>

            <div className="row">
              <div className="col-3">
                <img src="/Img/Eye1.png" className="w-100 h-100" alt="" />
              </div>
              <div className="col-3">
                <img src="/Img/eye2.png" className="w-100 h-100" alt="" />
              </div>
              <div className="col-3">
                <img src="/Img/eye3.png" className="w-100 h-100" alt="" />
              </div>
              <div className="col-3">
                <img src="/Img/eye4.png" className="w-100 h-100" alt="" />
              </div>
            </div>
          </section>

          {/* <!-- follow us on instagram --> */}
        </div>
      </>
    );
} 
