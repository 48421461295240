import React, { useEffect, useState } from 'react'
import { json, Link, NavLink, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { CgProfile } from "react-icons/cg";
import { FaStar } from "react-icons/fa";
import { FaHeart } from "react-icons/fa";
import { FaCartShopping } from "react-icons/fa6";

export default function Header() {
    let { pathname } = useLocation();
    const userInfo = JSON.parse(localStorage.getItem('UserInfo'));
    const userID = JSON.parse(localStorage.getItem("UserID"));
    const [openNav, setOpenNav] = useState(true)
    let userId = userInfo?.id;
    console.log(pathname)


    const navigate = useNavigate();
    return (
        <>
            <div className='bg-white' style={{ overflowX: 'hidden', boxShadow: '0px 0px 4px 0px', position:'sticky',top:'0px',zIndex:'9999' }}>
            {/* <header className='px-2 py-3'>
                <div className='row'>
                    <div className='order-xl-1 order-lg-1 order-md-1 order-sm-1 order-1 col-xl-2 col-lg-3 col-md-3 col-sm-3 col-6'>
                        <a className="navbar-brand p-0" href="http://eyemastr.com/">
                            <img src="/Img/headerLogo.png" alt="Logo" className='w-100' />
                        </a>
                    </div>
                    <div className='order-xl-3 order-lg-3 order-md-3 order-sm-3 order-3 col-xl-4 col-lg-8 col-md-8 col-sm-7 col-12 mt-xl-0 mt-lg-3 mt-sm-3 mt-3'>
                        <div className="div_input_search w-100" >
                            <input className="form-control me-2" type="search" placeholder="What are you looking for?" aria-label="Search" />
                            <i className="fa fa-search search_icon"></i>
                        </div>
                    </div>
                    <div className='order-xl-3 order-lg-2 order-md-2 order-sm-2 order-2 col-xl-5 col-lg-9 col-md-9 col-sm-9 col-12  mt-sm-0 mt-3'>
                        <div className="d-flex align-items-center justify-content-end">
                            <NavLink to="#" className="nav-link text-dark p-md-2 p-sm-0 p-0 pe-md-3 pe-sm-2 pe-3 d-md-block d-sm-none d-none">Track Order</NavLink>
                            <NavLink to="#" className="nav-link text-dark p-md-2 p-sm-0 p-0 pe-md-3 pe-sm-2 pe-3">Sign In & Sign Up</NavLink>
                            {pathname == '/' ? userID?.id ? <CgProfile className="pointer" size={20} onClick={() => { navigate("/profileView") }} />
                                : <button className='btn btn-primary' onClick={() => { navigate("/signup") }} >
                                    Register As Eyemast<FaStar color='#FFD700' />r
                                </button> : ''}
                        </div>
                    </div>
                    <div className='order-xl-4 order-lg-4 order-md-4 order-sm-4 order-4 col-xl-2 col-lg-4 col-md-4 col-sm-5 col-12 mt-xl-0 mt-lg-3 mt-sm-3 mt-3'>
                        <div className="d-flex align-items-center justify-content-end">
                            <NavLink to="#" className="nav-link text-dark p-md-2 p-sm-0 p-0 ps-md-3 ps-sm-2 ps-3 d-md-none d-sm-block d-block">Track Order</NavLink>
                            <NavLink to="#" className="nav-link text-dark p-2"><FaHeart/></NavLink><span className="ms-md-2 ms-sm-1 ms-1">Wishlist</span>
                            <NavLink to="#" className="nav-link text-dark p-2"><FaCartShopping /></NavLink><span className="ms-md-2 ms-sm-1 ms-1">Cart</span>
                        </div>
                    </div>
                </div>
            </header> */}
            <header className='px-2 py-3'>
                <div className='row'>
                    <div className='col-lg-3 col-md-4 col-12'>
                    <a className="navbar-brand p-0" href="http://eyemastr.com/">
                            <img src="/Img/headerLogo.png" alt="Logo" className='w-100' />
                        </a>
                    </div>
                    {/* <div className='col-lg-6 col-md-6 col-12 mt-md-0 mt-3'>
                    <div className="div_input_search w-100" >
                            <input className="form-control me-2" type="search" placeholder="What are you looking for?" aria-label="Search" />
                            <i className="fa fa-search search_icon"></i>
                        </div>
                    </div> */}
                </div>
            </header>
            {/* <nav class="navbar navbar-expand-lg navbar-light bg-light">
                <div class="container-fluid">
                    <button class="navbar-toggler ms-auto" type="button" onClick={() => setOpenNav(!openNav)} data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class={`${openNav ? 'collapse' : ''} navbar-collapse`} id="navbarSupportedContent">
                        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item" onClick={()=>setOpenNav(!openNav)}>
                                <NavLink className="nav-link" to="#">New Arrivals</NavLink>
                            </li>
                            <li className="nav-item" onClick={()=>setOpenNav(!openNav)}>
                                <NavLink className="nav-link" to="#">Eyeglasses</NavLink>
                            </li>
                            <li className="nav-item" onClick={()=>setOpenNav(!openNav)}>
                                <NavLink className="nav-link" to="#">Computer Eyeglasses</NavLink>
                            </li>
                            <li className="nav-item" onClick={()=>setOpenNav(!openNav)}>
                                <NavLink className="nav-link" to="#">Sunglasses</NavLink>
                            </li>
                            <li className="nav-item" onClick={()=>setOpenNav(!openNav)}>
                                <NavLink className="nav-link" to="#">Prescription Sunglasses</NavLink>
                            </li>
                            <li className="nav-item" onClick={()=>setOpenNav(!openNav)}>
                                <NavLink className="nav-link" to="#">Kids Eyeglasses</NavLink>
                            </li>
                        </ul>
                        <div className="d_flex_icon">
                            <button className="btn btn-sm btn-outline-success mx-1">TRY ON</button>
                            <button className="btn btn-sm btn-outline-primary mx-1">BLU</button>
                            <button className="btn btn-sm btn-outline-dark mx-1">GOLD</button>
                        </div>
                    </div>
                </div>
            </nav> */}
            </div>
        </>
    )
}
