import React from "react";
import style from "./Loader.module.css";
import { Spinner } from "reactstrap";

export default function Loader({ open }) {
  return (
    <>
      {open ? (
        <div className={style.overlay}>
          <div className={style.loader}>
            <div className={style.spinner}>Loading...</div>
          </div>
        </div>
      ) : null}
    </>
  );
}
